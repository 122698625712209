@import "../../styles/mixins";
@import "../../styles/colors";

button.muklit-button {
  @include display-flex-row;
  @include cursor-pointer;
  @include transition-ui(background-color);
  box-sizing: border-box;
  align-items: center;
  outline: 0;

  div.label {
    @include flex-auto-fill;
    @include font-proxima-semi-bold;
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-transform: uppercase;

    &-left {
      text-align: left;
    }

    &-center {
      text-align: center;
    }

    &-right {
      text-align: right;
    }
  }

  div.icon {
    @include size-width-height(24px, 24px);
    -webkit-mask-size: 24px 24px;

    &-plus {
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-plus.svg");
    }

    &-search {
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-search.svg");
    }

    &-show {
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-show.svg");
    }

    &-unlock {
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-unlock.svg");
    }
  }

  > div:first-child {
    margin-left: 0;
  }

  &-type-label-only {
    padding: 0 16px;

    div.icon {
      display: none;
    }
  }

  &-type-label-with-icon {
    padding: 0 16px;

    div.icon {
      margin: 0 0 0 16px !important;
    }
  }

  &-type-icon-only {
    width: auto !important;

    div.label {
      display: none;
    }
  }

  &-kind-primary {
    color: $color-text-04;
    background-color: $color-interactive-01;

    div.icon {
      background-color: $color-text-04;
    }

    // Hover
    &:hover {
      background-color: $color-hover-interactive-01;
    }

    // Active
    &:active {
      background-color: $color-active-interactive-01;
    }

    &.muklit-button-disabled {
      cursor: default !important;
      color: $color-disabled-03;
      background-color: $color-disabled-02;

      &:hover {
        background-color: $color-disabled-02;
      }
    }
  }

  &-kind-secondary {
    color: $color-text-04;
    background-color: $color-interactive-02;

    div.icon {
      background-color: $color-text-04;
    }

    &:hover {
      background-color: $color-hover-interactive-02;
    }

    &:active {
      background-color: $color-active-interactive-02;
    }

    &.muklit-button-disabled {
      cursor: default !important;
      color: $color-disabled-03;
      background-color: $color-disabled-02;

      &:hover {
        background-color: $color-disabled-02;
      }
    }
  }

  &-kind-tertiary {
    color: $color-interactive-01;
    border: 1px solid $color-interactive-01;

    div.icon {
      background-color: $color-interactive-01;
    }

    &:hover {
      background-color: $color-hover-tertiary;

      div.label {
        color: $color-text-04;
      }
    }

    &:active {
      border-color: #0071bd;
      background-color: $color-active-interactive-03;

      div.label {
        color: $color-text-04;
      }
    }

    &.muklit-button-disabled {
      cursor: default !important;
      color: $color-disabled-02;
      border-color: $color-disabled-02;

      div.icon {
        background-color: $color-disabled-02;
      }

      &:hover {
        background-color: inherit;
      }

      div.label {
        color: $color-disabled-02;
      }
    }
  }

  &-kind-ghost {
    color: $color-text-04;
    background-color: $color-text-03;

    div.icon {
      background-color: $color-interactive-02;
    }

    &:hover {
      background-color: $color-hover
;
    }

    &:active {
      background-color: $color-active-interactive-02;
    }

    &.muklit-button-disabled {
      cursor: default !important;
      color: $color-disabled-02;

      &:hover {
        background-color: inherit;
      }
    }
  }

  &-kind-danger {
    color: $color-text-04;
    background-color: $color-danger;

    div.icon {
      background-color: $color-text-04;
    }

    &:hover {
      background-color: $color-hover-danger;
    }

    &:active {
      background-color: $color-active-danger;
    }

    &.muklit-button-disabled {
      cursor: default !important;
      color: $color-disabled-03;
      background-color: $color-disabled-02;

      &:hover {
        background-color: $color-disabled-02;
      }
    }
  }

  &-size-small {
    height: 32px;

    div.icon {
      margin: 0 4px 0 4px;
    }
  }

  &-size-medium {
    height: 40px;

    div.icon {
      margin: 0 8px 0 8px;
    }
  }

  &-size-big {
    height: 48px;

    div.icon {
      margin: 0 12px 0 12px;
    }
  }

  &-size-tall {
    height: 56px;
    padding: 0 24px;

    div.icon {
      margin: 0 12px 0 12px;
    }
  }
}
