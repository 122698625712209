@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-inline-button {
  @include font-proxima-semi-bold;
  @include cursor-pointer;
  @include transition-ui(color);
  display: inline-block;
  position: relative;
  height: 24px;
  box-sizing: border-box;
  font-size: 14px;
  padding: 3px 18px 0 0;

  &:after {
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    right: 0;
    top: 4px;
    -webkit-mask-size: 16px 16px;
  }

  &-kind-up {
    &:after {
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-dart_up.svg");
    }
  }

  &-kind-down {
    &:after {
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-dart_down.svg");
    }
  }

  &-kind-right {
    &:after {
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-dart_right.svg");
    }
  }

  &-kind-left {
    &:after {
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-dart_left.svg");
    }
  }

  &-style-light {
    color: $color-hover;

    &:hover {
    }

    &:after {
      background-color: $color-hover;
    }
  }

  &-style-dark {
    color: $color-hover-dark;

    &:hover {
    }

    &:after {
      background-color: $color-hover-dark;
    }
  }
}
