@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-violation-form {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-ui-background;

  div.content {
    scrollbar-gutter: stable;

    div.form {
      width: 720px;
      margin: 0 auto;

      div.fieldset {
        background-color: $color-field-01;
        border-radius: 6px;
        padding: 24px 24px 16px 24px;
        margin-bottom: 40px;

        &-workflow {
          @include display-flex-row;
          flex-wrap: wrap;

          div.field-assignee {
            margin-right: 32px;
          }
        }
      }
    }

    div.section {
      width: 720px;
      margin: 0 auto;
      margin-bottom: 18px;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(16px, $color-ui-05);
        @include flex-auto-fill;
      }
    }

    div.properties {
      @include flex-auto-fill;
      width: 720px;
      box-sizing: border-box;
      background-color: $color-field-01;
      padding: 24px 24px 12px 24px;
      margin: 0 auto 24px auto;
      border-radius: 6px;

      div.note {
        @include font-proxima-medium;
        @include font-size-color(14px, $color-text-03);
        margin-bottom: 16px;
      }

      div.table {
        margin-bottom: 16px;

        table {
          width: 100%;

          tr {

            &:last-child {
              td {
                border-bottom: none;
              }
            }

            th {
              @include font-proxima-semi-bold;
              @include font-size-color(13px, $color-text-03);
              padding: 12px 12px 12px 0;
              border-bottom: 1px solid $color-ui-03;
            }

            td {
              border-bottom: 1px solid $color-ui-03;
              padding: 12px 12px 8px 0;

              &.label {
                @include font-proxima-regular;
                @include font-size-color(14px, $color-text-03);
              }

              &.value {
                @include font-proxima-regular;
                @include font-size-color(14px, $color-text-01);
              }

              a {
                @include font-proxima-semi-bold;
                color: $color-interactive-01;
              }
            }
          }
        }
      }
    }
  }
}
