@import "~muklit/styles/_mixins.scss";
@import "~muklit/styles/_colors.scss";

div.invipo-item-map {
  @include flex-auto-fill;
  @include transition-ui(opacity);
  height: 100%;
  position: relative;
  z-index: 0;
  background-color: $color-ui-background;
  overflow: hidden;

  div.placeholder {
    position: absolute;
    z-index: 1;

    &-control {
      right: 24px;
      bottom: 24px;
    }

    &-history {
      right: 24px;
      bottom: 80px;
    }
  }
}
