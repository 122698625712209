@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-inbox-browser {
  @include display-flex-row;
  @include box-shadow;
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  overflow: hidden;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-ui-background;
  border-radius: 6px;

  div.menu {
    @include size-width-height(320px, 100%);
    overflow: hidden;
  }

  div.form {
    @include size-width-height(320px, 100%);
    display: none;
    box-sizing: border-box;
    padding: 32px 24px 0 24px;
    border-right: 1px solid $color-ui-01;
    overflow: hidden;
  }

  div.data {
    @include flex-auto-fill;
    height: 100%;
    overflow: hidden;
  }
}
