@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-monitoring-detail {
  @include size-width-height(600px, 100%);
  @include transition-ui(min-width);
  box-sizing: border-box;
  z-index: 100;
}
