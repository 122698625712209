@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/animations";

div.invipo-item-detail {
  @include size-width-height(460px, 100%);
  @include transition-ui(min-width);
  box-sizing: border-box;
  /*margin: 8px;
  border-radius: 8px;*/

  > div.navigation {
    display: none;
  }
}
