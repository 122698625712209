// Light color tokens
$color-ui-background: #ffffff;
$color-field-01: #f5f5f5;
$color-field-02: #ffffff;
$color-field-03: #ffffff;
$color-field-04: #ffffff;
$color-icon-01: #161616;
$color-icon-02: #666666;
$color-icon-03: #ffffff;
$color-icon-04: #ffffff;
$color-icon-05: #999999;
$color-skeleton-01: #e5e5e5;
$color-skeleton-02: #c6c6c6;
$color-scrollbar-01: rgba(51, 51, 51, 0.6);
$color-overlay-01: rgba(22, 22, 22, 0.28);
$color-shadow-01: rgba(0, 0, 0, 0.1);
$color-shadow-02: rgba(0, 0, 0, 0.04);
$color-ui-01: #f4f4f4;
$color-ui-02: #ffffff;
$color-ui-03: #dedfe0;
$color-ui-04: #909191;
$color-ui-05: #161616;
$color-ui-06: rgba(153, 153, 153, 0.35);
$color-ui-07: rgba(143, 143, 143, 0.1);
$color-ui-08: rgba(10, 12, 13, 0.05);
$color-ui-09: rgba(0, 0, 0, 0.35);
$color-ui-10: rgba(43, 45, 47, 0.9);
$color-ui-11: #e9eaeb;
$color-ui-12: #bababa;
$color-ui-13: rgba(0, 0, 0, 0.08);
$color-ui-14: rgba(255, 255, 255, 0.8);
$color-ui-15: #f4f4f4;
$color-text-error: #da1e28;
$color-text-01: #161616;
$color-text-02: #393939;
$color-text-03: #949494;
$color-text-04: #ffffff;
$color-text-05: #6f6f6f;
$color-text-06: rgba(141, 141, 141, 0.35);
$color-text-07: #161616;
$color-text-08: rgba(255, 255, 255, 0.6);
$color-marker-01: #666666;
$color-marker-02: rgba(102, 102, 102, 0.3);
$color-disabled-01: #f4f4f4;
$color-disabled-02: #C2C2C2;
$color-disabled-03: #b3b3b3;
$color-disabled-04: rgba(198, 198, 198, 0.35);
$color-interactive-01: #008efa;
$color-interactive-02: #a3a3a3;
$color-interactive-03: #00a2ff;
$color-interactive-05: rgba(0, 162, 255, 0.35);
$color-danger: #ec003f;
$color-border-disabled-02: #c6c6c6;
$color-new: rgba(255, 246, 0, 0.1);
$color-active-danger: #750e13;
$color-active-interactive-01: #0071bd;
$color-active-interactive-02: #7a7a7a;
$color-active-interactive-03: #0071bd;
$color-hover: rgba(0, 0, 0, 0.05);
$color-hover-danger: #ba1b23;
$color-hover-interactive-01: #0085eb;
$color-hover-interactive-02: #575757;
$color-hover-tertiary: #0085eb;
$color-selected-01: rgba(0, 0, 0, 0.08);
$color-inverse-01: #ffffff;
$color-highlight-02: rgba(0, 145, 255, 0.15);
$color-highlight-03: rgba(0, 150, 250, 0.10);
$color-highlight-04: rgba(0, 162, 255, 0.50);
$color-support-01: #ec003f;
$color-support-02: #00cc3a;

// Dark color tokens
$color-ui-background-dark: #121416;
$color-field-01-dark: #27292b;
$color-field-02-dark: #1a1d1f;
$color-field-03-dark: #36383b;
$color-field-04-dark: #27292b;
$color-icon-01-dark: #f4f4f4;
$color-icon-02-dark: #9fa0a1;
$color-icon-03-dark: #ffffff;
$color-icon-04-dark: #161616;
$color-icon-05-dark: #656566;
$color-skeleton-01-dark: #353535;
$color-skeleton-02-dark: #393939;
$color-scrollbar-01-dark: rgba(204, 204, 204, 0.6);
$color-overlay-01-dark: rgba(22, 22, 22, 0.7);
$color-shadow-01-dark: rgba(0, 0, 0, 0.1);
$color-shadow-02-dark: rgba(0, 0, 0, 0.06);
$color-ui-01-dark: #1f2224;
$color-ui-02-dark: #373839;
$color-ui-03-dark: #373839;
$color-ui-04-dark: #636466;
$color-ui-05-dark: #f4f4f4;
$color-ui-06-dark: rgba(122, 125, 128, 0.35);
$color-ui-07-dark: rgba(135, 138, 141, 0.1);
$color-ui-08-dark: rgba(0, 0, 0, 0.4);
$color-ui-09-dark: rgba(255, 255, 255, 0.35);
$color-ui-10-dark: rgba(43, 45, 47, 0.9);
$color-ui-11-dark: #313336;
$color-ui-12-dark: #3a3c40;
$color-ui-13-dark: rgba(255, 255, 255, 0.08);
$color-ui-14-dark: rgba(0, 0, 0, 0.8);
$color-ui-15-dark: #393939;
$color-text-error-dark: #ff4d56;
$color-text-01-dark: #f4f4f4;
$color-text-02-dark: #c6c6c6;
$color-text-03-dark: #8a8a8a;
$color-text-04-dark: #ffffff;
$color-text-05-dark: #8d8d8d;
$color-text-06-dark: rgba(111, 111, 111, 0.35);
$color-marker-01-dark: #999999;
$color-marker-02-dark: rgba(153, 153, 153, 0.3);
$color-disabled-01-dark: #262626;
$color-disabled-02-dark: #666666;
$color-disabled-03-dark: #6f6f6f;
$color-disabled-04-dark: rgba(82, 82, 82, 0.35);
$color-interactive-01-dark: #00a2ff;
$color-interactive-02-dark: #575757;
$color-interactive-03-dark: #ffffff;
$color-interactive-05-dark: rgba(0, 162, 255, 0.35);
$color-danger-dark: #ec003f;
$color-new-dark: rgba(255, 246, 0, 0.1);
$color-active-danger-dark: #750e13;
$color-active-interactive-01-dark: #0071bd;
$color-active-interactive-02-dark: #393939;
$color-active-inveractive-03-dark: #c6c6c6;
$color-hover-dark: rgba(255, 255, 255, 0.1);
$color-hover-danger-dark: #ba1b23;
$color-hover-interactive-01-dark: #f4f4f4;
$color-hover-interactive-02-dark: #606060;
$color-hover-tertiary-dark: #f4f4f4;
$color-selected-01-dark: rgba(255, 255, 255, 0.08);
$color-inverse-01-dark: #161616;
$color-highlight-02-dark: rgba(0, 145, 255, 0.2);
$color-highlight-03-dark: rgba(0, 150, 250, 0.1);
$color-highlight-03-dark: rgba(0, 162, 255, 0.5);
$color-support-01-dark: #fa323c;
$color-support-02-dark: #26be51;
