@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../../styles/colors";

div.invipo-traffic-light-marker {

  div.light {

    &:hover {
      @include cursor-pointer;
      border: 4px solid transparent;
      border-radius: 3px;
      background: $color-hover-dark;
    }

    svg {
      display: block;
      width: 18px;
    }
  }

  &.muklit-map-marker-selected {
    div.light {
      border: 4px solid transparent;
      border-radius: 3px;
      background: rgba(0,162,255,0.5);
    }
  }

  svg {
    &.red {
      .red {
        fill: $color-traffic-control-light-red;
      }

      .red-flashing {
        animation: flashingRed 2s ease infinite;
        animation-delay: 0s;

        &:nth-child(odd) {
            animation-delay: 1s;
        }
      }

      .stop {
        stroke: #000;
        fill: #fff;
      }
    }

    &.red-yellow {
      .red {
        fill: $color-traffic-control-light-red;
      }

      .yellow {
        fill: $color-traffic-control-light-yellow;
      }
    }

    &.yellow {
      .yellow {
        fill: $color-traffic-control-light-yellow;
      }
    }

    &.yellow-green {
      .yellow {
        fill: $color-traffic-control-light-yellow;
      }
      .green {
        fill: $color-traffic-control-light-green;
      }
    }

    &.flashingYellow {
      .yellow {
        animation: flashingYellow 2s ease infinite;
      }
    }

    &.green {
      .green {
        fill: $color-traffic-control-light-green;
      }

      .white {
        fill: #fff;
      }

      .white-flashing {
        animation: flashingWhite 2s ease infinite;
      }

      .go {
          stroke: #000;
          fill: #fff;
      }
    }

    // flashingRed 1s ease infinite;
    @keyframes flashingRed { 0% { fill: #666; } 50% { fill: #666; } 50.1% { fill: $color-traffic-control-light-red; } 100% { fill: $color-traffic-control-light-red; } }
    @keyframes flashingYellow { 0% { fill: #666; } 50% { fill: #666; } 50.1% { fill: $color-traffic-control-light-yellow; } 100% { fill: $color-traffic-control-light-yellow; } }
    @keyframes flashingWhite { 0% { fill: #666; } 50% { fill: #666; } 50.1% { fill: #fff; } 100% { fill: #fff; } }
  }

}
