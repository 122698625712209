@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-radio-button {

  div.item {
    @include display-flex-row;
    align-items: center;
    margin-bottom: 8px;

    input {
      display: none;
    }

    div.icon {
      @include size-width-height(20px, 20px);
      @include cursor-pointer;
      -webkit-mask-size: 20px 20px;
      -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-radio-off.svg");
    }

    div.label {
      @include font-proxima-regular;
      cursor: default;
      font-size: 14px;
      margin-left: 8px;
    }

    &-checked {
      div.icon {
        -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-radio-on.svg");
      }
    }

    &-disabled {
      div.icon {
        cursor: not-allowed;
      }
    }
  }

  &-style-light {
    div.item {
      div.icon {
        background-color: $color-ui-04;
      }

      div.label {
        color: $color-text-01;
      }

      &-checked {
        div.icon {
          background-color: $color-ui-05;
        }
      }

      &-disabled {
        div.icon {
          background-color: $color-disabled-02;
        }
      }
    }
  }

  &-style-dark {
    div.item {
      div.icon {
        background-color: $color-ui-04-dark;
      }

      div.label {
        color: $color-text-01-dark;
      }

      &-checked {
        div.icon {
          background-color: $color-ui-05-dark;
        }
      }

      &-disabled {
        div.icon {
          background-color: $color-disabled-02-dark;
        }
      }
    }
  }

}
