@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-picker-menu {
  max-width: 1248px;
  margin: 0 auto;

  > div.title {
    @include font-proxima-semi-bold;
    @include font-size-color(16px, $color-text-01);
    @include transition-ui(background-color);
    margin-bottom: 16px;
  }

  > div.group {
    @include display-flex-row;
    flex-wrap: wrap;
    margin-bottom: 40px;

    div.item {
      @include display-flex-row;
      @include cursor-pointer;
      width: 620px;
      box-sizing: border-box;
      background-color: $color-field-01;
      align-items: center;
      padding: 16px 16px 16px 16px;
      border-radius: 4px;
      margin: 0 4px 4px 0;

      &:hover {
        background-color: $color-hover;
      }

      div.icon {
        @include size-width-height(40px, 40px);
        position: relative;
        background-color: $color-ui-background;
        border-radius: 50%;
        margin-right: 16px;

        &:after {
          @include size-width-height(24px, 24px);
          content: "";
          position: absolute;
          left: 8px;
          top: 8px;
          background-color: $color-icon-02;
          -webkit-mask-size: 24px 24px;
        }

        &-default {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-switch.svg");
          }
        }

        &-data {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
          }
        }

        &-trigger {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-switch.svg");
          }
        }

        &-change {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-changelog.svg");
          }
        }

        &-difference {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-history.svg");
          }
        }

        &-access {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-arrow-2-two.svg");
          }
        }

        &-road-work {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-roadwork.svg");
          }
        }

        &-road-hazard {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-hazard.svg");
          }
        }

        &-task {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-attachment.svg");
          }
        }

        &-offense {
          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-phone.svg");
          }
        }
      }

      div.title {
        div.label {
          @include font-proxima-semi-bold;
          @include font-size-color(16px, $color-text-01);
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }
      }
    }
  }
}
