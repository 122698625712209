@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-log-browser {
  @include flex-auto-fill;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-ui-background-dark;
}
