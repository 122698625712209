@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-query-table {
  @include size-width-height(100%, 100%);
  @include display-flex-column;
  position: relative;
  overflow: hidden;

  > div.bar {
    padding: 16px 24px 16px 24px;
  }

  > div.table {
    @include flex-auto-fill;
    padding: 0 24px 0 24px;
    overflow: auto;
  }

  > div.pagination {
    padding: 24px 24px 24px 40px;
  }
}
