@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-fleet-asset-list {

  div.content {
    div.item {
      div.icon {
        width: 12px;
        height: 12px;
        min-width: 12px;
        min-height: 12px;

        position: relative;
        top: 2px;
      }

      div.icon:after {
        display: none;
      }

      div.icon-0 {
         background-color: #999999;
      }
      div.icon-1 {
         background-color: #0fd170;
      }
      div.icon-2 {
         background-color: #ff0038;
      }
      div.icon-3 {
         background-color: #fcb836;
      }
      div.icon-4 {
         background-color: #7d00ff;
      }
      div.icon-5 {
         background-color: #c128b9;
      }
    }
  }

}
