@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-image-detail {
  @include position-center-optical;
  box-sizing: border-box;
  position: relative;
  overflow: visible;

  &:hover {
    div.close {
      opacity: 1;
    }
  }

  > div.close {
    @include size-width-height(40px, 40px);
    @include transition-ui(opacity);
    @include cursor-pointer;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: $color-field-01-dark;
    border-radius: 4px;
    opacity: 0;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-icon-02-dark;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
    }
  }

  > div.image {
    width: 1920px;
    height: 1080px;
    overflow: hidden;
    border-radius: 6px;

    img {
      width: 100%;
      //object-fit: cover;
      display: block;
      cursor: zoom-in;
    }
  }

  > div.preview {
    @include display-flex-row;
    position: absolute;
    left: 12px;
    bottom: 12px;

    div.image {
      @include cursor-pointer;
      width: 120px;
      height: 68px;
      border: 4px solid $color-field-01-dark;
      margin-right: -4px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        display: block;
      }
    }
  }

  &-zoomed {
    div.image {
      img {
        width: auto;
        min-width: 100%;
        cursor: zoom-out;
      }
    }
  }
}

@media only screen and (max-width: 1920px) {
  div.invipo-image-detail {
    div.image {
      width: 1280px;
      height: 720px;
    }
  }
}

@media only screen and (max-width: 1280px) {
  div.invipo-image-detail {
    div.image {
      width: 1024px;
      height: 576px;
    }
  }
}
