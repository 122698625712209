@import "muklit/styles/mixins";
@import "muklit/styles/colors";

div.partial-tag {
  @include display-flex-row;
  align-items: center;
  box-sizing: border-box;
  white-space: nowrap;
  border-radius: 14px;
  padding: 6px 0;
  margin: 2px 4px 2px 0;

  &-disabled {
    cursor: default;
  }

  div.label {
    @include font-proxima-regular;
    font-size: 12px;
    user-select: none;
    padding: 0 12px;

    &:not(:last-child) {
      padding-right: 6px;
    }
  }

  div.icon {
    @include cursor-pointer;
    @include size-width-height(16px, 16px);
    -webkit-mask-size: 16px 16px;
    -webkit-mask-image: url("muklit/assets/images/icons/tools/24/24-tools-clear_filter.svg");
    margin-right: 6px;
  }

  &-style-light {
    color: $color-text-04;
    background-color: $color-interactive-01;

    div.icon {
      background-color: $color-text-04;
    }
  }

  &-style-dark {
    color: $color-text-04-dark;
    background-color: $color-interactive-01-dark;

    div.icon {
      background-color: $color-text-04-dark;
    }
  }
}
