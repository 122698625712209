@import "positions";
@import "colors";

// Loader animation
div.muklit-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;

  div.loader-bar {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    overflow: hidden;
    z-index: 1;

    &:after {
      content: "";
      display: block;
      position: relative;
      background: $color-interactive-01;
      width: 0;
      height: 100%;
      animation: keyframes-loader-loading 2400ms ease-in-out 400ms infinite alternate;
    }
  }

  @keyframes keyframes-loader-loading {
    0%  {
      left: -9.5%;
      width: 10%;
    }
    50%  {
      width: 40%;
    }
    100%  {
      left: 99.5%;
      width: 10%;
    }
  }
}
