@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-event-list {

  div.content {
    div.item {
      div.icon {
        &-accident {
          background-color: #ec383a;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-accident.svg");
          }
        }

        &-closure {
          background-color: #ffbb00;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-restriction_02.svg");
          }
        }

        &-danger {
          background-color: #c128b9;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-danger.svg");
          }
        }

        &-roadworks {
          background-color: #298cff;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-roadwork.svg");
          }
        }

        &-info {
          background-color: #47cff5;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-road_non_weather.svg");
          }
        }
      }
    }
  }

}
