@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../styles/colors";

div.invipo-city-navigator {
  @include size-width-height(320px, 100%);
  box-sizing: border-box;
  background-color: $color-text-04;
  border-radius: 4px;
  margin-bottom: 8px;

  div.switch {
  }

  > div.header {
    padding: 24px 12px 24px 12px;

    div.title {
      @include cursor-pointer;
      display: inline-flex;
      flex-flow: row;
      align-items: center;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(20px, $color-icon-01);
      }

      div.icon {
        @include size-width-height(16px, 16px);
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
        background-color: $color-icon-01;
        margin-left: 4px;
      }
    }
  }

  > div.subdomains {
    padding: 0 12px 12px 12px;
  }
}
