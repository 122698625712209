@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-krnap-dashboard {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  > div.board {
    @include size-width-height(720px, 100%);
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;
    padding: 24px 24px 32px 24px;
  }

  > div.map {
    @include flex-auto-fill;
    overflow: hidden;
    margin: 24px;
    border-radius: 6px;
  }

  > div.column {
    @include size-width-height(312px, 100%);
    padding: 24px 0 24px 24px;

    div.name {
      @include font-proxima-semi-bold;
      @include font-size-color(19px, $color-ui-05);
      margin-bottom: 24px;
      //padding-left: 12px;
    }

    div.tiles {
    }
  }
}