@import "positions";

// Overlay layer for panels and dialogs
div.muklit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.35;
  animation: keyframes-fade-in-03 300ms;

  @media print {
    display: none;
  }
}
