@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-notification-toast {
  @include box-shadow;
  width: 320px;
  box-sizing: border-box;
  min-height: 52px;
  background-color: $color-ui-background-dark;
  border-radius: 4px;
  padding: 16px 16px 16px 16px;
  margin-top: 4px;
  user-select: none;
  animation: keyframes-fade-in-01 300ms;

  /*&:hover {
    background-color: lighten($color-field-01-dark, 5%)
  }*/

  div.header {
    @include display-flex-row;
    justify-content: center;

    div.icon {
      @include size-width-height(20px, 20px);
      margin-right: 12px;
      -webkit-mask-size: 20px 20px;
    }

    div.close {
      @include size-width-height(20px, 20px);
      @include cursor-pointer;
      background-color: $color-icon-02-dark;
      margin-left: 20px;
      -webkit-mask-size: 20px 20px;
      -webkit-mask-image: url("../../assets/images/icons/tools/24/24-tools-close.svg");

      &:hover {
        background-color: $color-inverse-01;
      }
    }

    div.title {
      @include flex-auto-fill;
      padding-top: 2px;

      div.label {
        @include font-proxima-semi-bold;
        font-size: 14px;
        color: $color-ui-01;
        margin-right: 12px;
        margin-bottom: 6px;
      }

      div.message {
        @include font-proxima-regular;
        font-size: 14px;
        color: $color-ui-01;
      }

      div.timestamp {
        @include font-proxima-regular;
        font-size: 14px;
        color: $color-text-03-dark;
        margin-top: 16px;
      }
    }
  }

  div.actions {
    @include display-flex-row;
    margin-top: 16px;
    justify-content: flex-end;

    div.action {
      @include font-proxima-semi-bold;
      @include cursor-pointer;
      color: $color-active-interactive-01-dark;
      font-size: 14px;
      white-space: nowrap;
      margin-left: 16px;
    }
  }

  div:last-child {
    margin-bottom: 0;
  }

  &-kind-info {
    div.icon {
      background-color: $color-ui-04;
      -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-info.svg");
    }
  }

  &-kind-error {
    div.icon {
      background-color: $color-support-01;
      -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-error.svg");
    }
  }

  &-kind-success {
    div.icon {
      background-color: $color-support-02;
      -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-success.svg");
    }
  }
}
