@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../../styles/colors";

div.invipo-traffic-detector-marker {
  div.detector {
    width: 18px;
    height: 30px;

    &:hover {
      @include cursor-pointer;

      svg {
        polygon {
          fill: $color-hover-dark;
        }
      }
    }

    svg {
      path {
        fill: $color-ui-05;
      }

      polygon {
        fill: transparent;
      }
    }

    &-occupied {
      svg {
        polygon {
          fill: $color-traffic_control-light-yellow !important;
        }
      }
    }

    &-call {
      svg {
        path {
          fill: $color-traffic_control-mode-special;
        }
      }
    }
  }

  &.muklit-map-marker-selected {
    div.detector {
      svg {
        polygon {
          fill: $color-highlight-04;
        }
      }
    }
  }

}
