@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-traffic-event-form {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-ui-background;

  div.content {
    scrollbar-gutter: stable;

    form {
      width: 720px;
      margin: 0 auto;
    }

    div.fieldset {
      @include display-flex-row;
      flex-wrap: wrap;
      background-color: $color-field-01;
      border-radius: 6px;
      padding: 24px 24px 16px 24px;
      margin-bottom: 40px;

      div.field-from {
        margin-right: 32px;
      }

      &-location {
        .field-position-type {
          flex-basis: 100%;
        }

        .field-from-latitude, .field-to-latitude {
          margin-right: 32px;
        }

        .invipo-design-map {
          width: 672px;
          height: 300px;
        }

        .invipo-basic-map {
          width: 672px;
          height: 300px;
        }
      }

      &-info {
        input {
          color: #C2C2C2;
        }
      }
    }

    div.buttons {
      @include display-flex-row;
      justify-content: end;
      width: 720px;
      margin: 0 auto;


      div.button {
        @include display-flex-row;
        @include cursor-pointer;
        position: relative;
        align-items: center;
        justify-content: center;

        &:before {
          @include transition-ui(background-color);
          content: "";
          position: absolute;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          &:before {
            background-color: $color-hover;
          }
        }

        div.label {
          @include font-proxima-semi-bold;
          font-size: 14px;
          color: $color-text-04;
        }

        &-cancel {
          @include size-width-height(160px, 48px);
          background-color: $color-interactive-02;
          margin-right: 4px;
        }

        &-confirm {
          @include size-width-height(256px, 48px);
          background-color: $color-interactive-01;
        }

        &-escalated {
          background-color: $color-danger;

          &:hover {
            background-color: $color-hover-danger;
          }
        }
      }
    }

  }

}
