@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

form.muklit-form {

  div.title {
    @include font-proxima-semi-bold;
    font-size: 16px;
    margin-bottom: 16px;
  }

  div.fieldset {
    div.section {
      @include font-proxima-semi-bold;
      font-size: 13px;
      padding: 20px 0 12px 0;
      margin-bottom: 12px;
      border-bottom: 1px solid $color-ui-03;

      &:first-child {
        padding-top: 0;
      }
    }

    div.field {
      margin-bottom: 24px;

      &-collapsed {
        display: none !important;
      }

      &-hidden {
        display: none;
      }
    }

    div.note {
      @include font-proxima-regular;
      font-size: 14px;
      line-height: 20px;
      position: relative;
      padding: 2px 0 24px 36px;

      &:before {
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        top: 0;
        -webkit-mask-size: 24px 24px;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-info-2.svg");
      }
    }
  }

  &-style-light {
    div.title {
      color: $color-text-01;
    }

    div.fieldset {
      div.section {
        color: $color-text-03;
      }

      div.note {
        color: $color-text-01;

        &:before {
          background-color: $color-icon-05;
        }
      }
    }
  }

  &-style-dark {
    div.title {
      color: $color-text-01-dark;
    }

    div.fieldset {
      div.section {
        color: $color-text-03-dark;
      }

      div.note {
        color: $color-text-01-dark;

        &:before {
          background-color: $color-icon-05-dark;
        }
      }
    }
  }
}
