@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-map-control {
  @include display-flex-row;
  background-color: $color-ui-background;
  border-radius: 6px;

  div.icon {
    @include size-width-height(40px, 40px);
    @include cursor-pointer;
    position: relative;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      -webkit-mask-size: 24px 24px;
    }

    &-disabled {
      cursor: not-allowed !important;
    }

    &-zoom-in {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-plus.svg");
      }
    }

    &-zoom-out {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-minus.svg");
      }
    }

    &-zoom-all {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-zoom_to_all.svg");
      }
    }

    &-zoom-home {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-zoom-selected.svg");
      }
    }

    &-map-style {
      &:after {
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-map_style.svg");
      }
    }
  }

  &-style-light {
    div.icon {
      background-color: $color-ui-background;

      &:after {
        background-color: $color-text-02;
      }

      &:hover {
        //background-color: $color-hover;
      }

      &-disabled {
        &:hover {
          background-color: $color-ui-background !important;
        }

        &:after {
          background-color: $color-disabled-02 !important;
        }
      }
    }
  }

  &-style-dark {
    div.icon {
      background-color: $color-field-01-dark;

      &:after {
        background-color: $color-text-02-dark;
      }

      &:hover {
        //background-color: $color-hover-dark;
      }

      &-disabled {
        &:hover {
          background-color: $color-ui-background-dark !important;
        }

        &:after {
          background-color: $color-disabled-02-dark !important;
        }
      }
    }
  }
}
