@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-invipo-menu {
  @include display-flex-column;
  @include size-width-height(56px, 100%);
  position: relative;
  background-color: $color-ui-background-dark;
  z-index: $z-index-invipo;

  div.logo {
    @include size-width-height(32px, 20px);
    @include cursor-pointer;
    background-color: $color-ui-01;
    -webkit-mask-size: 32px 20px;
    -webkit-mask-image: url("~muklit/assets/images/logo/20/invipo_logo_in_20_w.svg");
    margin: 16px auto 40px auto;
  }

  div.item {
    @include display-flex-row;
    @include cursor-pointer;
    position: relative;
    box-sizing: border-box;
    align-items: center;
    overflow-x: visible;
    border-left: 3px solid transparent;

    &:hover {
      div.icon {
        &:after {
          background-color: #ffffff;
        }
      }
    }

    div.shortcut {
      @include size-width-height(40px, 40px);
      @include font-proxima-medium;
      @include transition-ui(color);
      box-sizing: border-box;
      position: relative;
      font-size: 14px;
      color: rgba($color-ui-03, 0.5);
      text-align: center;
      text-transform: uppercase;
      padding-top: 11px;
      margin: 4px 8px 4px 5px;

      &:hover {
        color: $color-ui-01;
      }
    }

    div.icon {
      @include size-width-height(40px, 40px);
      position: relative;
      margin: 4px 8px 4px 5px;

      &:after {
        @include transition-ui(background-color);
        content: "";
        position: absolute;
        width: 24px;
        height: 24px;
        left: 8px;
        top: 8px;
        background-color: rgba($color-ui-03, 0.5);
        -webkit-mask-size: 24px 24px;
      }

      div.count {
        @include font-proxima-semi-bold;
        position: absolute;
        right: 4px;
        top: 4px;
        width: 12px;
        height: 12px;
        overflow: hidden;
        color: $color-ui-background;
        background-color: $color-interactive-01;
        font-size: 10px;
        text-align: center;
        z-index: 2;
        border: 1px solid $color-ui-background-dark;
        border-radius: 8px;
      }

      &-dashboard {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-dashboard.svg");
        }
      }

      &-city {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-city.svg");
        }
      }

      &-data {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/v2/24/24-data-02.svg");
        }
      }

      &-insights {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-statistics.svg");
        }
      }

      &-devices,
      &-inventory {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-evidence.svg");
        }
      }

      &-items {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-evidence.svg");
        }
      }

      &-monitoring {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-monitoring_02.svg");
        }
      }

      &-settings {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-settings.svg");
        }
      }

      &-schemas {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-schema.svg");
        }
      }

      &-wall {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-video_wall.svg");
        }
      }

      &-dashboard {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-dashboard-02.svg");
        }
      }

      &-desk {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-content_manager.svg");
        }
      }

      &-journal {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-journal.svg");
        }
      }

      &-user {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-user.svg");
        }
      }

      &-management {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-plans.svg");
        }
      }

      &-inbox {
        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-notification-02.svg");
        }
      }
    }

    div.label {
      @include font-proxima-semi-bold;
      display: none;
      height: 48px;
      box-sizing: border-box;
      font-size: 14px;
      color: $color-text-05;
      white-space: nowrap;
      padding: 15px 16px 0 4px;
      z-index: 2;
    }

    &-monitoring {
      margin-bottom: auto;
    }

    &-user {
      margin-bottom: 28px;
    }

    &-selected {
      border-left: 3px solid $color-interactive-01;

      /*&:hover {
        div.icon {
          background-color: transparent;
        }
      }*/

      div.icon {
        &:after {
          background-color: $color-ui-01;
        }
      }
    }

    &-hovered {
      background-color: $color-interactive-01;

      div.icon {
        background-color: transparent;

        &:after {
          background-color: $color-ui-01;
        }
      }

      div.label {
        display: block;
        color: $color-ui-01;
        background-color: $color-interactive-01;
      }
    }
  }

  @media print {
    display: none;
  }
}

// Menu transparency on home and dashboard view
body.view-home,
body.view-dashboard {
  div.invipo-invipo-menu {
    background-color: rgba(#000000, 0.25);
  }
}

// Meni is hidden on wall view
body.view-wall {
  div.invipo-invipo-menu {
    //display: none;
  }
}

