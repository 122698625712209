@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-stats-detail {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  > div.form {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 24px 16px 0 16px;

    div.fieldset {
      box-sizing: border-box;
      padding: 16px 16px 8px 16px;
      background-color: $color-ui-01;
      margin-bottom: 16px;
      border-radius: 8px;
    }
  }

  > div.chart {
    @include flex-auto-fill;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px 24px 24px;
    overflow: hidden;
  }
}
