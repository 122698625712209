@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-registered-data-browser {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;
  background-color: $color-ui-background-dark;
}
