@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-calendar {
  @include display-flex-column;
  @include box-shadow;
  position: absolute;
  width: 350px;
  max-height: 600px;
  overflow: auto;
  z-index: $z-index-menu;
  background-color: $color-field-01;
  user-select: none;
  border-radius: 4px;

  div.control {
    @include display-flex-row;
    justify-content: center;

    div {
      @include font-proxima-regular;
      @include display-flex-row;
      justify-content: center;
      align-items: center;

      &.icon {
        @include size-width-height(40px, 40px);
        @include cursor-pointer;
        position: relative;

        &:after {
          position: absolute;
          top: 9px;
          width: 20px;
          height: 20px;
          content: "";
          background-color: $color-icon-02;
          mask-size: 20px 20px;
          -webkit-mask-size: 20px 20px;
        }

        &-left {
          &:after {
            mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_left.svg");
            -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_left.svg");
          }
        }

        &-right {
          &:after {
            mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_right.svg");
            -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_right.svg");
          }
        }

        &:hover {
          background-color: $color-hover;
        }

        &-disabled {
          cursor: default !important;

          &:after {
            background-color: $color-disabled-02;
          }

          &:hover {
            background-color: unset !important;
          }
        }
      }

      &.month {
        @include font-proxima-semi-bold;
        @include size-width-height(auto, 40px);
        @include font-size-color(14px, $color-text-01);
        flex-grow: 1;
      }
    }
  }

  div.labels {
    @include display-flex-row;
    justify-content: center;

    div {
      @include display-flex-row;
      @include size-width-height(50px, 40px);
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-01);
      justify-content: center;
      align-items: center;
    }
  }

  div.week {
    @include display-flex-row;
    justify-content: center;

    div.day {
      @include display-flex-row;
      @include size-width-height(50px, 40px);
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-01);
      @include cursor-pointer;
      justify-content: center;
      align-items: center;

      &:hover {
        background-color: $color-hover;
      }

      &-past, &-future {
        @include font-size-color(14px, $color-text-03);
      }

      &-today {
        @include font-size-color(14px, $color-interactive-01);
        position: relative;

        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: 5px;
          width: 6px;
          height: 6px;
          border-radius: 6px;
          background-color: $color-interactive-01;
        }
      }

      &-selected {
        background-color: $color-interactive-01;
        color: $color-ui-02;

        &-range {
          background-color: $color-interactive-05;
        }

        &.day-today:after {
            background-color: $color-ui-02;
        }

        &:hover {
          background-color: $color-interactive-01;
        }
      }
    }
  }


  &-style-dark {
    background-color: $color-field-01-dark;

    div.control {
      div {
        &.icon {
          &:after {
            background-color: $color-icon-02-dark;
          }

          &-disabled {
            &:after {
              background-color: $color-disabled-02-dark;
            }
          }
        }

        &.month {
          @include font-size-color(14px, $color-text-01-dark);
        }
      }
    }

    div.labels {
      div {
        @include font-size-color(14px, $color-text-01-dark);
      }
    }

    div.week {
      div.day {
        @include font-size-color(14px, $color-text-01-dark);

        &:hover {
          background-color: $color-hover-dark;
        }

        &-past, &-future {
          @include font-size-color(14px, $color-text-03-dark);
        }

        &-today {
          @include font-size-color(14px, $color-interactive-01-dark);

          &:after {
            background-color: $color-interactive-01-dark;
          }
        }

        &-selected {
          background-color: $color-interactive-01-dark;
          color: $color-ui-02-dark;

          &-range {
            background-color: $color-interactive-05-dark;
          }

          &.day-today:after {
              background-color: $color-ui-02-dark;
          }

          &:hover {
            background-color: $color-interactive-01-dark;
          }
        }
      }
    }
  }
}
