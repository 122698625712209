@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-docker-log-detail {
  @include size-width-height(61.8%, 80%);
  @include position-center-optical;
  box-sizing: border-box;

  div.header {
    background-color: $color-field-01-dark;
    padding: 8px 16px 8px 24px;
  }

  div.content {
    @include font-size-color(13px, $color-text-03-dark);
    font-family: Consolas, "Andale Mono", "Courier New", serif;
    box-sizing: border-box;
    background-color: #000000;
    white-space: nowrap;
    padding: 8px 24px 24px 24px;

    div.line {
      span.timestamp {
        color: $color-interactive-02-dark;
      }

      span.message {
        color: $color-text-03-dark;
      }
    }
  }
}
