@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-camera-overview {

  > div.cameras {
    @include display-flex-row;
    flex-wrap: wrap;

    div.camera {
      position: relative;
      width: 240px - 4px;
      height: 135px;
      background-color: $color-field-01;
      border-radius: 4px;
      overflow: hidden;

      &:nth-child(2) {
        margin: 0 6px;
      }

      img {
        @include transition-ui(opacity);
        @include cursor-pointer;
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

}
