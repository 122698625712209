@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-ticket-manager {
  @include flex-auto-fill;
  @include display-flex-column;
  overflow: hidden;

  > div.filter {
    padding: 16px 24px 16px 24px;
  }

  > div.overview {
    @include flex-auto-fill;
    overflow: hidden;
  }

  > div.board {
    @include flex-auto-fill;
    overflow: hidden;
    padding-bottom: 24px;
  }

  > div.table {
    @include flex-auto-fill;
    overflow: hidden;
  }
}
