@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-surface-status {
  @include display-flex-row;
  align-items: center;

  div.status {
    @include size-width-height(8px, 8px);
    border-radius: 50%;
    background-color: #dddddd;
    margin: 0 6px 0 2px;

    &-dry {
      background-color: #080;
    }

    &-moist {
      background-color: #0ff;
    }

    &-wet {
      background-color: #080;
    }

    &-standing-water {
      background-color: #00f;
    }

    &-bright-ice {
      background-color: #f00;
    }

    &-dark-ice {
      background-color: #400;
    }

    &-snow {
      background-color: #800;
    }

    &-slush {
      background-color: #f80;
    }

    &-fog {
      background-color: #888;
    }

    &-freezing-rain {
      background-color: #088;
    }
  }

  div.label {
    @include font-proxima-regular;
    font-size: 14px;
  }

  &-style-light {
    div.label {
      color: $color-text-02;
    }
  }

  &-style-dark {
    div.label {
      color: $color-text-02-dark;
    }
  }
}
