@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-item-list {

  div.content {
    div.item {
      div.icon {
        &-traffic-counter {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-counter.svg");
          }
        }

        &-traffic-light-controller {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-traffic_light.svg");
          }
        }

        &-wim-station {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-wim.svg");
          }
        }

        &-warning-light {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-traffic_light.svg");
          }
        }

        &-v2x-unit {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-counter.svg");
          }
        }

        &-environmental-sensor {
          background-color: $color-domain-environment;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/devices/24/24-markers-devices-env_sensor.svg");
          }
        }

        &-street-light-controller {
          background-color: $color-domain-energy;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/devices/24/24-markers-devices-street-light.svg");
          }
        }

        &-lora-gateway {
          background-color: $color-domain-energy;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-parking-lot {
          background-color: $color-domain-parking;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/parking/24/24-markers-parking-parking.svg");
          }
        }

        &-parking-sensor {
          background-color: $color-domain-parking;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-parking-sign {
          background-color: $color-domain-parking;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-vms.svg");
          }
        }

        &-bicycle-pump-station {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-counter_bike.svg");
          }
        }

        &-full-matrix-sign {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-vms.svg");
          }
        }

        &-line-matrix-sign {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-vms.svg");
          }
        }

        &-road-weather-station {
          background-color: $color-domain-environment;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-road_weather.svg");
          }
        }

        &-network-gateway {
          background-color: $color-domain-energy;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-battery {
          background-color: $color-domain-energy;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-ups.svg");
          }
        }

        &-energy-meter {
          background-color: $color-domain-energy;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-traffic-camera {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
          }
        }

        &-bluetooth-detector {
          background-color: $color-domain-safety;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-security-camera {
          background-color: $color-domain-safety;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
          }
        }

        &-radar {
          background-color: $color-domain-safety;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-camera {
          background-color: $color-domain-safety;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-cctv.svg");
          }
        }

        &-garbage-bin {
          background-color: $color-domain-waste;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-waste.svg");
          }
        }

        &-wifi-access-point {
          background-color: $color-domain-services;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/modules/24/24-modules-wifi.svg");
          }
        }

        &-svd-system {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
          }
        }

        &-rlvd-system {
          background-color: $color-domain-traffic;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
          }
        }
      }
    }
  }

}
