@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/animations";

div.invipo-feature-form {
  width: 320px;
  border-radius: 4px;
  background-color: $color-ui-background;

  div.content {
    padding-bottom: 0;
  }
}
