@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-sign-info-input {
    div.lines {
        margin-top: 24px;
    }

    div.add {
        @include display-flex-row;
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-interactive-01);
        @include cursor-pointer;
        justify-content: center;
        align-items: center;
        border: 2px solid $color-interactive-01;
        height: 44px;
        width: 176px;
        margin: 24px auto 0 auto;
    }
}