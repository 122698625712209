@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-public-vehicle-list {

  div.content {
    div.item {
      div.icon {
        &-bus {
          background-color: $color-domain-transit;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/transport/24/24-markers-transport-bus.svg");
          }
        }
      }
    }
  }

}
