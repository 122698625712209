@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-filter {
  div.header {
    @include display-flex-row;
    height: 40px;
    align-items: center;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      font-size: 19px;
      white-space: nowrap;
      margin-right: 24px;
    }

    div.tab {
      @include font-proxima-semi-bold;
      @include cursor-pointer;
      @include transition-ui(border-bottom-color);
      height: 40px;
      box-sizing: border-box;
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 12px;
      margin-right: 16px;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      border-bottom-color: transparent;

      &:last-child {
        margin-right: 0;
      }

      &-disabled {
        cursor: not-allowed;
      }
    }
  }

  div.filter {
    @include display-flex-row;
    height: 28px;
    align-items: center;
    margin: 12px 0 0 -8px;

    div.toggler {
      @include transition-ui(background-color);
      @include cursor-pointer;
      box-sizing: border-box;
      border-radius: 14px;
      padding: 2px 8px 2px 8px;
      margin: 2px 8px 2px 0;

      div.icon {
        @include size-width-height(24px, 24px);
        -webkit-mask-size: 24px 24px;
        -webkit-mask-image: url("muklit/assets/images/icons/tools/24/24-tools-filter.svg");
      }

      &:hover {
        background-color: $color-hover;
      }

      &-selected {
        background-color: $color-highlight-03;

        &:hover {
          background-color: $color-highlight-03;
        }

        div.icon {
          background-color: $color-interactive-01;
        }
      }
    }

    div.spacer {
      @include flex-auto-fill;
    }
  }

  &-style-light {
    div.header {
      div.title {
        color: $color-text-02;
      }

      div.tab {
        color: $color-text-03;

        &:hover {
          color: $color-text-01;
        }

        &-selected {
          color: $color-text-01 !important;
          border-bottom-color: $color-text-01 !important;
        }

        &-disabled {
          color: $color-text-06 !important;
        }
      }
    }

    div.filter {
      div.toggler {
        div.icon {
          background-color: $color-text-03;
        }

        &-selected {
          background-color: $color-highlight-03;

          &:hover {
            background-color: $color-highlight-03;
          }

          div.icon {
            background-color: $color-interactive-01;
          }
        }
      }
    }
  }

  &-style-dark {
    div.header {
      div.title {
        color: $color-text-02-dark;
      }

      div.tab {
        color: $color-text-03-dark;

        &:hover {
          color: $color-text-01-dark;
        }

        &:hover {
          background-color: $color-hover;
        }

        &-selected {
          color: $color-text-01-dark !important;
          border-bottom-color: $color-text-01-dark !important;
        }

        &-disabled {
          color: $color-text-06-dark !important;
        }
      }
    }

    div.filter {
      div.toggler {
        div.icon {
          background-color: $color-text-03-dark;
        }

        &:hover {
          background-color: $color-hover-dark;
        }

        &-selected {
          background-color: $color-highlight-03-dark;

          &:hover {
            background-color: $color-highlight-03-dark;
          }

          div.icon {
            background-color: $color-interactive-01-dark;
          }
        }
      }
    }
  }
}
