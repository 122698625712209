@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-raw-data-browser {
  @include flex-auto-fill;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-ui-background-dark;
}
