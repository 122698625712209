@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.muklit-traffic-event-form-marker {
  div.circle {
    height: 10px;
    width: 10px;
    background-color: rgb(21, 184, 0);
    border: 2px solid #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  &.selected div.circle {
    background-color: rgb(211, 0, 0);
  }
}
