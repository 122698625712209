@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-monitoring-search {
  @include transition-ui("width");
  width: 400px;
  border-radius: 4px;
  background-color: $color-field-01-dark;
  box-shadow: 0 2px 8px rgba(#000000, 0.4);

  div.header {
    padding: 4px 8px 4px 8px;

    div.input {
      position: relative;

      input {
        @include font-proxima-regular;
        @include font-size-color(14px, $color-text-01-dark);
        width: 100%;
        height: 40px;
        background-color: transparent;
        box-sizing: border-box;
        outline: none;
        padding: 0 48px;
      }

      div.search {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 8px;
        top: 8px;
        background-color: $color-ui-09-dark;
        -webkit-mask-size: 24px 24px;
        -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-search.svg");
      }

      div.clear {
        @include cursor-pointer;
        position: absolute;
        width: 20px;
        height: 20px;
        right: 8px;
        top: 10px;
        background-color: $color-ui-09-dark;
        -webkit-mask-size: 20px 20px;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/20/20-notification-disabled.svg");

        &:hover {
          background-color: $color-ui-05-dark;
        }
      }
    }
  }

  div.content {
    display: none;

    div.group {
      padding: 12px 16px;
      border-top: 1px solid $color-ui-03-dark;

      div.title {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-text-03-dark);
        padding: 0 0 8px 8px;
      }

      div.item {
        @include display-flex-row;
        @include cursor-pointer;
        @include transition-ui(background-color);
        align-items: flex-start;
        overflow: hidden;
        padding: 4px 8px 5px 8px;
        margin-bottom: 2px;
        border-radius: 4px;


        &:hover {
          background-color: $color-hover-dark;
        }

        div.icon {
          @include size-width-height(24px, 24px);
          margin-right: 8px;
          -webkit-mask-size: 24px 24px;
          background-color: $color-icon-02-dark;

          &-area {
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-area.svg");
          }

          &-class {
            -webkit-mask-image: url("~muklit/assets/images/icons/menu/24/24-menu-devices.svg");
          }
        }

        div.status {
          @include size-width-height(10px, 10px);
          border-radius: 50%;
          margin: 4px 8px 0 0;
          background-color: #dddddd;

          &-ok {
            background-color: $color-status-ok;
          }

          &-warn {
            background-color: $color-status-warn;
          }

          &-error {
            background-color: $color-status-error;
          }

          &-unreachable {
            background-color: $color-status-unreachable;
          }

          &-disconnected {
            background-color: $color-status-disconnected;
          }
        }

        div.name {
          div.label {
            @include font-proxima-semi-bold;
            @include font-size-color(14px, $color-text-01-dark);
          }

          div.description {
            @include font-proxima-regular;
            @include font-size-color(12px, $color-text-05-dark);
            margin-top: 2px;
          }
        }

        &-selected {
          background-color: $color-hover-dark;
        }
      }
    }
  }

  &-open {
    width: 400px;
    box-shadow: 0 2px 8px rgba(#000000, 0.4) !important;

    div.content {
      display: block;
    }
  }

  &-empty {
    box-shadow: none;

    div.input {
      div.clear {
        display: none;
      }
    }
  }
}
