@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-label {
  @include display-flex-row;
  align-items: center;
  //align-items: flex-start;

  div.icon {
    @include size-width-height(24px, 24px);
    margin-right: 4px;
    -webkit-mask-size: 24px 24px;

    &-warn {
      -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-error.svg");
    }

    &-error {
      -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-error.svg");
    }

    &-info {
      -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-info.svg");
    }

    &-success {
      -webkit-mask-image: url("~muklit/assets/images/icons/notification/24/24-notification-success.svg");
    }

    &-card {
      -webkit-mask-image: url("~muklit/assets/images/icons/payment/24/24-payment-card.svg");
    }

    &-car {
      -webkit-mask-image: url("~muklit/assets/images/icons/transport/24/24-transport-car.svg");
    }

    &-camera {
      -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
    }

    &-lock {
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-lock.svg");
    }
  }

  div.label {
    @include font-proxima-regular;
    font-size: 14px;

    &-semi-bold {
      @include font-proxima-semi-bold;
    }
  }

  &-style-light {
    div.icon {
      background-color: $color-text-02;
    }

    div.label {
      color: $color-text-02;
    }
  }

  &-style-dark {
    div.icon {
      background-color: $color-text-02-dark;
    }

    div.label {
      color: $color-text-02-dark;
    }
  }

  &-style-alert {
    div.icon {
      background-color: $color-support-01-dark;
    }

    div.label {
      color: $color-support-01-dark;
    }
  }

  &-style-success {
    div.icon {
      background-color: $color-support-02;
    }
  }

  &-style-id {
    div.icon {
      @include size-width-height(20px, 20px);
      background-color: $color-text-05;
      margin-right: 2px;
      -webkit-mask-size: 20px 20px;
    }

    div.label {
      color: $color-text-02;
      text-transform: uppercase;
    }
  }
}
