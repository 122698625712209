@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-pulsing-marker {

  div.circle {
    height: 160px;
    width: 160px;
    border-radius: 50%;
    transform: scale(0);
    background-color: rgba(0, 0, 0, 0.3);
  }

  &-type-selection {
    div.circle {
      animation: pulse 2400ms cubic-bezier(0.215, 0.61, 0.355, 1) 1 400ms;
    }
  }

  &-type-broadcast {
    div.circle {
      animation: pulse 2400ms cubic-bezier(0.215, 0.61, 0.355, 1) 3;
    }
  }

  &-type-monitoring {
    div.circle {
      animation: pulse 2400ms cubic-bezier(0.215, 0.61, 0.355, 1) 1;
    }
  }

  &-type-request {
    div.circle {
      animation: pulse 2400ms cubic-bezier(0.215, 0.61, 0.355, 1) 1;
    }
  }

  &-style-light {
    div.circle {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  &-style-dark {
    div.circle {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(0);
    }

    80%, 100% {
      opacity: 0;
      transform: scale(1);
    }
  }
}
