@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-cis-wall {
  @include flex-auto-fill;
  height: 100%;
  background-color: $color-ui-background-dark;
  overflow: hidden;

  div.maps {
    @include display-flex-row;
    @include size-width-height(100%, 50%);
    overflow: hidden;

    div.map {
      @include size-width-height(50%, 100%);
    }
  }
}
