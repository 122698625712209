@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-user-profile {
  @include flex-auto-fill;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-ui-background;

  div.header {
    @include display-flex-row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 16px 24px;

    div.title {
      @include font-proxima-semi-bold;
      font-size: 19px;
      @include display-flex-row;
      align-items: center;
      height: 40px;
    }

    div.logout {
      @include cursor-pointer;
      @include font-proxima-semi-bold;
      font-size: 14px;
      color: $color-interactive-01;
    }
  }

  div.section {
    @include display-flex-row;
    border-bottom: 1px solid $color-ui-03;
    margin: 0 24px;
    padding: 24px 0;
    position: relative;

    &:last-child { border-bottom: none; }

    > div.label {
      @include font-proxima-semi-bold;
      font-size: 16px;
      min-width: 176px;
      padding-right: 24px;
    }

    div.content {
      @include font-proxima-regular;
      color: $color-text-02;
      font-size: 12px;
      max-width: 584px;

      div.description {
        @include display-flex-row;
        margin-bottom: 24px;

        &:empty {
          display: none;
        }
      }

      > div.muklit-text-input {
        min-width: 280px;
        margin-right: 24px;

        div.label {
          @include font-proxima-regular;
          color: $color-text-02;
          font-size: 12px;
        }
      }
    }

    div.buttons {
      @include display-flex-row;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1;

      &.hidden {
        display: none;
      }

      button {
        @include cursor-pointer;
        @include font-proxima-semi-bold;
        font-size: 14px;
        padding: 12px 24px;
        min-width: 120px;
        white-space: nowrap;

        &.submit {
          background-color: $color-interactive-01;
          color: $color-inverse-01;
          margin-left: 8px;
        }

        &.cancel {
          background-color: transparent;
          color: $color-interactive-01;
        }
      }
    }

    &.account {
      div.picture {
        @include display-flex-row;
        margin-bottom: 24px;

        > div {
          width: 160px;
          height: 160px;
          background-color: #eee;
          margin-right: 16px;
          position: relative;

          &.hidden { display: none; }

          img {
            width: 160px;
            height: 160px;
          }

          div.remove {
            @include cursor-pointer;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 20px;
            height: 20px;
            background-color: rgba($color: $color-ui-09-dark, $alpha: 1);
            mask-size: 20px 20px;
            -webkit-mask-size: 20px 20px;
            mask-image: url("../../../../muklit/assets/images/icons/notification/20/20-notification-disabled.svg");
            -webkit-mask-image: url("../../../../muklit/assets/images/icons/notification/20/20-notification-disabled.svg");
          }

          &.upload {
            @include display-flex-column;
            justify-content: center;
            align-items: center;

            @include font-proxima-semi-bold;
            font-size: 14px;
            text-align: center;

            @include cursor-pointer;

            background-color: rgba($color: $color-interactive-01, $alpha: 0.1);
            color: $color-interactive-01;
            border: 1px dashed $color-interactive-01;

            div.icon {
              width: 24px;
              height: 24px;
              margin-bottom: 14px;
              background-color: $color-interactive-01;
              mask-size: 24px 24px;
              -webkit-mask-size: 24px 24px;
              mask-image: url("../../../../muklit/assets/images/icons/tools/24/24-tools-upload.svg");
              -webkit-mask-image: url("../../../../muklit/assets/images/icons/tools/24/24-tools-upload.svg");
            }
          }
        }
      }

      div.fieldset-name , div.fieldset-contacts {
        @include display-flex-row;

        div.field:first-child {
          margin-right: 20px;
        }
      }
    }

    &.security {
      div.fieldset-new-password {
        @include display-flex-row;

        div.field:first-child {
          margin-right: 20px;
        }
      }

      div.otp {
        flex-direction: column;

        span {
          display: block;
          margin-bottom: 10px;
        }

        div.muklit-toggle {
          margin-bottom: 0;
        }
      }
    }

    &.roles {
      div.muklit-checkbox div.label {
        color: $color-active-inveractive-03-dark;
      }
    }
  }
}
