// Status colors
$color-status-ok: #0fd170;
$color-status-warn: #fcb836;
$color-status-error: #ff0038;
$color-status-unreachable: #7d00ff; // #7a8199
$color-status-disconnected: #ffffff;
$color-status-availability: #7d00ff;

$color-status-ok-dark: #0fd170;
$color-status-warn-dark: #fcb836;
$color-status-error-dark: #ff0038;
$color-status-unreachable-dark: #7d00ff;
$color-status-disconnected-dark: #ffffff;
$color-status-availability-dark: #7d00ff;

// Severity colors
$color-severity-info: #666666;
$color-severity-warn: #fcb836;
$color-severity-error: #ff0038;

$color-severity-info-dark: #666666;
$color-severity-warn-dark: #fcb836;
$color-severity-error-dark: #ff0038;

// Availability colors
$color-availability-available: #0fd170;
$color-availability-na: #ff0038;

$color-availability-available-dark: #0fd170;
$color-availability-na-dark: #ff0038;


// Domain colors
$color-domain-infrastructure: #8e9b9f;
$color-domain-traffic: #d1c628;
$color-domain-roads: #199cc8;
$color-domain-environment: #00e3a6;
$color-domain-energy: #fac800;
$color-domain-safety: #ff7b43;
$color-domain-parking: #8b88ff;
$color-domain-transit: #0099ff;
$color-domain-waste: #00ab94;
$color-domain-services: #5d9bba;
$color-domain-city: #21bcff;
$color-domain-extravilan: #00e3a6;

$color-domain-traffic-dark: #d1c628;
$color-domain-roads-dark: #199cc8;
$color-domain-environment-dark: #00e3a6;
$color-domain-energy-dark: #fac800;
$color-domain-safety-dark: #ff7b43;
$color-domain-parking-dark: #8b88ff;
$color-domain-transit-dark: #0099ff;
$color-domain-waste-dark: #00e3a6;
$color-domain-services-dark: #5d9bba;
$color-domain-city-dark: #21bcff;
$color-domain-extravilan: #00e3a6;

// technologies
$color-technology-parking: #6e86ff;
$color-technology-access: #6b66ff;
$color-technology-dms: #895cfa;
$color-technology-charging_stations: #11e5d5;
$color-technology-traffic_events: #2bc3e4;
$color-technology-road_status: #199cc8;
$color-technology-traffic_control: #e0e031;
$color-technology-traffic_counters: #d1c628;
$color-technology-travel_time: #d1bb2a;
$color-technology-public_transport: #0099ff;
$color-technology-fleet: #007fd4;
$color-technology-bikes: #00e3a6;
$color-technology-environment: #00ccb1;
$color-technology-waste: #00b098;
$color-technology-violations: #ff6742;
$color-technology-lpr: #ff7b43;
$color-technology-weighing_vehicles: #ff9147;
$color-technology-cameras: #ffaf0c;
$color-technology-street_lights: #fac800;
$color-technology-sensors: #b8b4ab;
$color-technology-wifi_monitoring: #ada9a1;
$color-technology-payment_terminals: #999690;
$color-technology-ups: #8c8984;
$color-technology-news: #56a5cc;
$color-technology-public_services: #509bbf;
$color-technology-points_of_interests: #4686a6;
$color-technology-logbook: #8ca9b8;
$color-technology-scheduler: #84a0ad;
$color-technology-monitoring: #748d99;
$color-technology-tools: #6b818c;

$color-technology-parking-dark: #6e86ff;
$color-technology-access-dark: #6b66ff;
$color-technology-dms-dark: #895cfa;
$color-technology-charging_stations-dark: #11e5d5;
$color-technology-traffic_events-dark: #2bc3e4;
$color-technology-road_status-dark: #199cc8;
$color-technology-traffic_control-dark: #e0e031;
$color-technology-traffic_counters-dark: #d1c628;
$color-technology-travel_time-dark: #d1bb2a;
$color-technology-public_transport-dark: #0099ff;
$color-technology-fleet-dark: #007fd4;
$color-technology-bikes-dark: #00e3a6;
$color-technology-environment-dark: #00ccb1;
$color-technology-waste-dark: #00b098;
$color-technology-violations-dark: #ff6742;
$color-technology-lpr-dark: #ff7b43;
$color-technology-weighing_vehicles-dark: #ff9147;
$color-technology-cameras-dark: #ffaf0c;
$color-technology-street_lights-dark: #fac800;
$color-technology-sensors-dark: #b8b4ab;
$color-technology-wifi_monitoring-dark: #ada9a1;
$color-technology-payment_terminals-dark: #999690;
$color-technology-ups-dark: #8c8984;
$color-technology-news-dark: #56a5cc;
$color-technology-public_services-dark: #509bbf;
$color-technology-points_of_interests-dark: #4686a6;
$color-technology-logbook-dark: #8ca9b8;
$color-technology-scheduler-dark: #84a0ad;
$color-technology-monitoring-dark: #748d99;
$color-technology-tools-dark: #6b818c;

// Scales

//Scale 1
$color-scale1-1: #47cff5;
$color-scale1-2: #00dba3;
$color-scale1-3: #ffd944;
$color-scale1-4: #ff9046;
$color-scale1-5: #ec383a;
$color-scale1-6: #b90e6e;
$color-scale1-7: #790047;
$color-scale1-unknown: #cccccc;

$color-scale1-1-dark: #47cff5;
$color-scale1-2-dark: #00dba3;
$color-scale1-3-dark: #ffd944;
$color-scale1-4-dark: #ff9046;
$color-scale1-5-dark: #ec383a;
$color-scale1-6-dark: #b90e6e;
$color-scale1-7-dark: #790047;
$color-scale1-unknown-dark: #595959;

//Scale 2
$color-scale2-1: #3fe1fd;
$color-scale2-2: #27bbff;
$color-scale2-3: #3298ff;
$color-scale2-4: #3561fd;
$color-scale2-5: #342aff;
$color-scale2-6: #db00ac;
$color-scale2-unknown: #cccccc;

$color-scale2-1-dark: #3fe1fd;
$color-scale2-2-dark: #27bbff;
$color-scale2-3-dark: #3298ff;
$color-scale2-4-dark: #3561fd;
$color-scale2-5-dark: #342aff;
$color-scale2-6-dark: #db00ac;
$color-scale2-unknown-dark: #595959;

//Scale 3
$color-scale3-1: #0ffa71;
$color-scale3-2: #00d872;
$color-scale3-3: #fecf18;
$color-scale3-4: #ff9147;
$color-scale3-5: #ff4d4d;
$color-scale3-unknown: #cccccc;

$color-scale3-1-dark: #0ffa71;
$color-scale3-2-dark: #00d872;
$color-scale3-3-dark: #fecf18;
$color-scale3-4-dark: #ff9147;
$color-scale3-5-dark: #ff4d4d;
$color-scale3-unknown-dark: #595959;

// Environment

// Temperature
$color-env-temperature--40: #730097;
$color-env-temperature--30: #4f00b5;
$color-env-temperature--20: #3346ff;
$color-env-temperature--10: #52a5ff;
$color-env-temperature-0: #61e2ff;
$color-env-temperature-10: #43e043;
$color-env-temperature-20: #fcc900;
$color-env-temperature-30: #ff8838;
$color-env-temperature-40: #dc160d;
$color-env-temperature-50: #990037;
$color-env-temperature-60: #60003d;
$color-env-temperature-unknown: #cccccc;

$color-env-temperature--40-dark: #730097;
$color-env-temperature--30-dark: #4f00b5;
$color-env-temperature--20-dark: #3346ff;
$color-env-temperature--10-dark: #52a5ff;
$color-env-temperature-0-dark: #61e2ff;
$color-env-temperature-10-dark: #43e043;
$color-env-temperature-20-dark: #fcc900;
$color-env-temperature-30-dark: #ff8838;
$color-env-temperature-40-dark: #dc160d;
$color-env-temperature-50-dark: #990037;
$color-env-temperature-60-dark: #60003d;
$color-env-temperature-unknown-dark: #595959;

// Pressure
$color-env-pressure-1: #008cd0;
$color-env-pressure-2: #00acff;
$color-env-pressure-3: #7cd4ff;
$color-env-pressure-4: #ff9046;
$color-env-pressure-5: #c94f16;
$color-env-pressure-unknown: #cccccc;

$color-env-pressure-1-dark: #008cd0;
$color-env-pressure-2-dark: #00acff;
$color-env-pressure-3-dark: #7cd4ff;
$color-env-pressure-4-dark: #ff9046;
$color-env-pressure-5-dark: #c94f16;
$color-env-pressure-unknown-dark: #595959;

// Humidity
$color-env-humidity-1: #e96a07;
$color-env-humidity-2: #64c078;
$color-env-humidity-3: #28bad7;
$color-env-humidity-4: #1b93cf;
$color-env-humidity-5: #35509d;
$color-env-humidity-unknown: #cccccc;

$color-env-humidity-1-dark: #e96a07;
$color-env-humidity-2-dark: #64c078;
$color-env-humidity-3-dark: #28bad7;
$color-env-humidity-4-dark: #1b93cf;
$color-env-humidity-5-dark: #35509d;
$color-env-humidity-unknown-dark: #595959;

// Visibility
$color-env-visibility-1: #c8349f;
$color-env-visibility-2: #dd335d;
$color-env-visibility-3: #eda623;
$color-env-visibility-4: #7ec458;
$color-env-visibility-5: #35509d;
$color-env-visibility-unknown: #cccccc;

$color-env-visibility-1-dark: #c8349f;
$color-env-visibility-2-dark: #dd335d;
$color-env-visibility-3-dark: #eda623;
$color-env-visibility-4-dark: #7ec458;
$color-env-visibility-5-dark: #35509d;
$color-env-visibility-unknown-dark: #595959;

// Visibility Type
$color-env-visibilitytype-unrestricted: #83faa7;
$color-env-visibilitytype-mist: #7deafe;
$color-env-visibilitytype-fog: #0acaf1;
$color-env-visibilitytype-haze: #98b5d6;
$color-env-visibilitytype-sand: #fbcc74;
$color-env-visibilitytype-smoke: #778797;
$color-env-visibilitytype-unknown: #cccccc;

$color-env-visibilitytype-unrestricted-dark: #83faa7;
$color-env-visibilitytype-mist-dark: #7deafe;
$color-env-visibilitytype-fog-dark: #0acaf1;
$color-env-visibilitytype-haze-dark: #98b5d6;
$color-env-visibilitytype-sand-dark: #fbcc74;
$color-env-visibilitytype-smoke-dark: #778797;
$color-env-visibilitytype-unknown-dark: #595959;

// Rain - Precipitation
$color-env-precipitation-1: #c7f6ff;
$color-env-precipitation-2: #40dbfa;
$color-env-precipitation-3: #31adf5;
$color-env-precipitation-4: #3666cb;
$color-env-precipitation-5: #322497;
$color-env-precipitation-6: #61249b;
$color-env-precipitation-unknown: #cccccc;

$color-env-precipitation-1-dark: #c7f6ff;
$color-env-precipitation-2-dark: #40dbfa;
$color-env-precipitation-3-dark: #31adf5;
$color-env-precipitation-4-dark: #3666cb;
$color-env-precipitation-5-dark: #322497;
$color-env-precipitation-6-dark: #61249b;
$color-env-precipitation-unknown-dark: #595959;

// Wind
$color-env-wind-1: #b7e0f9;
$color-env-wind-2: #00b4f2;
$color-env-wind-3: #43e043;
$color-env-wind-4: #fcc900;
$color-env-wind-5: #ff8838;
$color-env-wind-6: #ff0346;
$color-env-wind-7: #b30088;
$color-env-wind-unknown: #cccccc;

$color-env-wind-1-dark: #b7e0f9;
$color-env-wind-2-dark: #00b4f2;
$color-env-wind-3-dark: #43e043;
$color-env-wind-4-dark: #fcc900;
$color-env-wind-5-dark: #ff8838;
$color-env-wind-6-dark: #ff0346;
$color-env-wind-7-dark: #b30088;
$color-env-wind-unknown-dark: #595959;

// Parking

// Occupancy
$color-parking-occupancy-1: #00e66a;
$color-parking-occupancy-2: #fcc900;
$color-parking-occupancy-3: #ff4d4d;
$color-parking-occupancy-unknown: #cccccc;

$color-parking-occupancy-1-dark: #00e66a;
$color-parking-occupancy-2-dark: #fcc900;
$color-parking-occupancy-3-dark: #ff4d4d;
$color-parking-occupancy-unknown-dark: #595959;


// Roads

// Road surface status
$color-road-status-dry: #D9E45C;
$color-road-status-moist: #b0e260;
$color-road-status-wet: #54b94d;
$color-road-status-critically_wet: #087d00;
$color-road-status-chemically_wet: #00e7c0;
$color-road-status-thin_ice: #e1a067;
$color-road-status-light_snow: #74d3f7;
$color-road-status-light_frost: #e043a6;
$color-road-status-slush: #0c95b8;
$color-road-status-snow: #3087f5;
$color-road-status-frost: #aa006b;
$color-road-status-ice: #ff6f00;
$color-road-status-snow_and_ice: #ff0900;
$color-road-status-unknown: #cccccc;

$color-road-status-dry-dark: #D9E45C;
$color-road-status-moist-dark: #b0e260;
$color-road-status-wet-dark: #54b94d;
$color-road-status-critically_wet-dark: #098f00;
$color-road-status-chemically_wet-dark: #00e7c0;
$color-road-status-thin_ice-dark: #e1a067;
$color-road-status-light_snow-dark: #74d3f7;
$color-road-status-light_frost-dark: #e043a6;
$color-road-status-slush-dark: #0c95b8;
$color-road-status-snow-dark: #3087f5;
$color-road-status-frost-dark: #aa006b;
$color-road-status-ice-dark: #ff6f00;
$color-road-status-snow_and_ice-dark: #ff0900;
$color-road-status-unknown-dark: #595959;



//Traffic

// Traffic
$color-traffic-A: #43e043;
$color-traffic-B: #a8e500;
$color-traffic-C: #fcc900;
$color-traffic-D: #ffa200;
$color-traffic-E: #f13806;
$color-traffic-F: #b3000d;
$color-traffic-unknown: #cccccc;

$color-traffic-A-dark: #43e043;
$color-traffic-B-dark: #a8e500;
$color-traffic-C-dark: #fcc900;
$color-traffic-D-dark: #ffa200;
$color-traffic-E-dark: #f13806;
$color-traffic-F-dark: #b3000d;
$color-traffic-unknown-dark: #595959;

//Speed
$color-traffic-speed-1: #c9ea0d;
$color-traffic-speed-2: #00de83;
$color-traffic-speed-3: #0097f4;
$color-traffic-speed-4: #520ff1;
$color-traffic-speed-5: #e100ca;
$color-traffic-speed-unknown: #cccccc;

$color-traffic-speed-1-dark: #c9ea0d;
$color-traffic-speed-2-dark: #00de83;
$color-traffic-speed-3-dark: #0097f4;
$color-traffic-speed-4-dark: #520ff1;
$color-traffic-speed-5-dark: #e100ca;
$color-traffic-speed-unknown-dark: #595959;


//Volume
$color-traffic-volume-1: #00ec87;
$color-traffic-volume-2: #00cffe;
$color-traffic-volume-3: #5214ff;
$color-traffic-volume-4: #d400e1;
$color-traffic-volume-5: #d3007a;
$color-traffic-volume-unknown: #cccccc;

$color-traffic-volume-1-dark: #00ec87;
$color-traffic-volume-2-dark: #00cffe;
$color-traffic-volume-3-dark: #5214ff;
$color-traffic-volume-4-dark: #d400e1;
$color-traffic-volume-5-dark: #d3007a;
$color-traffic-volume-unknown-dark: #595959;


//Traffic lights

//traffic light
$color-traffic_control-light-green: #64f01b;
$color-traffic_control-light-yellow: #f1d200;
$color-traffic_control-light-red: #f81267;
$color-traffic_control-light-dark: #566797;
$color-traffic_control-light-unknown: #cccccc;

$color-traffic_control-light-green-dark: #64f01b;
$color-traffic_control-light-yellow-dark: #f1d200;
$color-traffic_control-light-red-dark: #f81267;
$color-traffic_control-light-dark-dark: #566797;
$color-traffic_control-light-unknown-dark: #595959;

//Control state
$color-traffic_control-state-on: #298cff;
$color-traffic_control-state-off: #be116f;
$color-traffic_control-state-flashing_yellow: #f1d200;
$color-traffic_control-state-dark: #566797;
$color-traffic_control-state-unknown: #cccccc;

$color-traffic_control-state-on-dark: #298cff;
$color-traffic_control-state-off-dark: #be116f;
$color-traffic_control-state-flashing_yellow-dark: #f1d200;
$color-traffic_control-state-dark-dark: #566797;
$color-traffic_control-state-unknown-dark: #595959;


//Control mode
$color-traffic_control-mode-local: #fcaa36;
$color-traffic_control-mode-central: #d1c628;
$color-traffic_control-mode-manual: #298cff;
$color-traffic_control-mode-special: #c128b9;
$color-traffic_control-mode-unknown: #cccccc;

$color-traffic_control-mode-local-dark: #fcaa36;
$color-traffic_control-mode-central-dark: #d1c628;
$color-traffic_control-mode-manual-dark: #298cff;
$color-traffic_control-mode-special-dark: #c128b9;
$color-traffic_control-mode-unknown-dark: #595959;

//Traffic coutners

//Class
$color-trafficdata-class-01-1: #dcf000; 
$color-trafficdata-class-01-2: #c5df00;
$color-trafficdata-class-01-3: #83b200;

$color-trafficdata-class-02-1: #00ffe4; 
$color-trafficdata-class-02-2: #00cdb5;
$color-trafficdata-class-02-3: #00b098;

$color-trafficdata-class-03-1: #f0dd00; 
$color-trafficdata-class-03-2: #d6c500;
$color-trafficdata-class-03-3: #b0a200;

$color-trafficdata-class-04-1: #00eaff; 
$color-trafficdata-class-04-2: #00b4f5;
$color-trafficdata-class-04-3: #005cf0;

$color-trafficdata-class-05-1: #da25ff; 
$color-trafficdata-class-05-2: #a200ff;
$color-trafficdata-class-05-3: #7001d1;

$color-trafficdata-class-06-1: #ffcd02; 
$color-trafficdata-class-06-1: #ffab02; 
$color-trafficdata-class-06-1: #f09000; 

$color-trafficdata-class-07-1: #fd6786; 
$color-trafficdata-class-07-2: #ff0158;
$color-trafficdata-class-07-3: #df004c;

$color-trafficdata-class-unknown: #cccccc; 



$color-trafficdata-class-01-1-dark: #dcf000; 
$color-trafficdata-class-01-2-dark: #c5df00;
$color-trafficdata-class-01-3-dark: #83b200;

$color-trafficdata-class-02-1-dark: #00ffe4; 
$color-trafficdata-class-02-2-dark: #00cdb5;
$color-trafficdata-class-02-3-dark: #00b098;

$color-trafficdata-class-03-1-dark: #f0dd00; 
$color-trafficdata-class-03-2-dark: #d6c500;
$color-trafficdata-class-03-3-dark: #b0a200;

$color-trafficdata-class-04-1-dark: #00eaff; 
$color-trafficdata-class-04-2-dark: #00b4f5;
$color-trafficdata-class-04-3-dark: #005cf0;

$color-trafficdata-class-05-1-dark: #da25ff; 
$color-trafficdata-class-05-2-dark: #a200ff;
$color-trafficdata-class-05-3-dark: #7001d1;

$color-trafficdata-class-06-1-dark: #ffcd02; 
$color-trafficdata-class-06-1-dark: #ffab02; 
$color-trafficdata-class-06-1-dark: #f09000; 

$color-trafficdata-class-07-1-dark: #fd6786; 
$color-trafficdata-class-07-2-dark: #ff0158;
$color-trafficdata-class-07-3-dark: #df004c;

$color-trafficdata-class-unknown-dark: #595959;










