@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.partial-image-thumbnail {
  overflow: hidden;
  position: relative;

  img {
    min-width: 100%;
    max-width: 100%;
    position: absolute;
    display: block;
    bottom: 0;
  }

  div.tag {
    @include font-proxima-regular;
    position: absolute;
    top: 12px;
    left: 12px;
    height: 24px;
    box-sizing: border-box;
    font-size: 12px;
    white-space: nowrap;
    border-radius: 12px;
    padding: 5px 10px 0 10px;
  }

  &-style-light {
    background-color: $color-ui-03;

    div.tag {
      color: $color-text-01;
      background-color: $color-ui-03;
    }
  }

  &-style-dark {
    background-color: $color-ui-03-dark;

    div.tag {
      color: $color-text-01-dark;
      background-color: $color-ui-03-dark;
    }
  }
}
