@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

body {
  @include transition-ui(opacity, 300ms);
  display: flex;
  flex-flow: row;
  overflow: hidden;

  &.view-home {
    background-color: #090c25;
  }

  &.debug {
    div[component] {
      border: 1px dashed #dd0000 !important;
    }
  }

  &.project-crossulmde {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/ulm-bg.jpg");
    }
  }

  &.project-unicotskcz {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/praha-bg.jpg");
    }
  }

  &.project-crosshrkcz {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/hradec_kralove-bg.jpg");
    }
  }

  &.project-alamenvisk {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
    }
  }

  &.project-crossesbdk {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
    }
  }

  &.project-verdoalbdk {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/aalborg-bg.jpg");
    }
  }

  &.project-krnappilcz {
    &.view-home,
    &.view-dashboard  {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/krnap-bg.jpg");
    }
  }

  &.project-crosshavcz {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/havirov-bg.jpg");
    }
  }

  &.project-crossvejdk {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/vejle-bg.jpg");
    }
  }

  &.project-crossciscz {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/cross-02-bg.jpg");
    }
  }

  &.project-crossrsdcz {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/RSD-bg.jpg");
    }
  }

  &.project-dotswpregr {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/preveza-bg.jpg");
    }
  }

  &.project-dotswkalgr {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/kalamaria-bg.jpg");
    }
  }

  &.project-dotswpatgr {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/patras-bg.jpg");
    }
  }

  &.project-crossegogr {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/egnatia-odos-bg.jpg");
    }
  }

  &.project-crosspocvn {
    &.view-home {
      background-size: cover;
      background-image: url("~muklit/assets/images/cities/vietnam-bg.jpg");
    }
  }

  &.project-etutpdubae {
    &.view-home,
    &.view-dashboard {
      background-size: cover;
      background-color: #163788;
      background-image: url("~muklit/assets/images/cities/dubai-bg.jpg");
    }
  }
}
