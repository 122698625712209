@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-checkbox {
  @include display-flex-row;
  align-items: center;

  input {
    display: none;
  }

  div.icon {
    @include size-width-height(16px, 16px);
    @include cursor-pointer;
    position: relative;
    box-sizing: border-box;
    border-width: 2px;
    border-style: solid;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 16px;
      height: 16px;
      left: -2px;
      top: -2px;
      -webkit-mask-size: 16px 16px;
    }
  }

  div.label {
    @include font-proxima-regular;
    cursor: default;
    font-size: 14px;
    margin-left: 10px;
    user-select: none;
  }

  &-disabled {
    div.icon {
      cursor: not-allowed;
    }
  }

  &-style-light {
    div.icon {
      border-color: $color-ui-04;
    }

    div.label {
      color: $color-text-01;
    }

    &.muklit-checkbox-bright {
      div.icon {
        background-color: $color-field-02;
      }
    }

    &.muklit-checkbox-checked {
      div.icon {
        border-color: $color-ui-05;
        background-color: $color-ui-05;

        &:after {
          background-color: $color-ui-background;
          -webkit-mask-image: url("../../assets/images/icons/tools/16/16-tools-switch-on.svg");
        }
      }
    }

    &.muklit-checkbox-disabled {
      div.icon {
        border-color: $color-disabled-02;
      }

      &.muklit-checkbox-checked {
        div.icon {
          background-color: $color-disabled-02;
        }
      }
    }
  }

  &-style-dark {
    div.icon {
      border-color: $color-ui-04-dark;
    }

    div.label {
      color: $color-text-01-dark;
    }

    &.muklit-checkbox-bright {
      div.icon {
        background-color: $color-field-02-dark;
      }
    }

    &.muklit-checkbox-checked {
      div.icon {
        border-color: $color-ui-05-dark;
        background-color: $color-ui-05-dark;

        &:after {
          background-color: $color-ui-background-dark;
          -webkit-mask-image: url("../../assets/images/icons/tools/16/16-tools-switch-on.svg");
        }
      }
    }

    &.muklit-checkbox-disabled {
      div.icon {
        border-color: $color-disabled-02-dark;
      }

      &.muklit-checkbox-checked {
        div.icon {
          background-color: $color-disabled-02-dark;
        }
      }
    }
  }
}
