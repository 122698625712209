@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-fleet-status-detail {
  // @include size-width-height(648px, 480px);
  @include size-width-height(960px, 720px);
  @include position-center-optical;
  animation: keyframes-detail-slide-in 300ms;

  > div.content > div.muklit-data-table {
    thead th:last-child {
      background: transparent;
    }

    a {
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-interactive-01);
      padding: 0 16px 0 16px
    }
  }

  > div.content-general {
    form {
      @include display-flex-row;

      div.fieldset:first-child {
        margin-right: 24px;
      }
    }
  }

  > div.content-map {
    padding: 0;
  }
}
