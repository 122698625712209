@import "muklit/styles/mixins";
@import "muklit/styles/colors";

div.partial-action {
  @include font-proxima-semi-bold;
  @include font-size-color(14px, $color-interactive-01);
  @include cursor-pointer;
  padding: 2px 0;
  margin-left: 16px;

  &-alert {
    color: $color-danger;
  }

  &-disabled {
    display: none;
    cursor: unset !important;
    color: $color-disabled-02;
  }
}
