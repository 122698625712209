body {
  transition: all 300ms cubic-bezier(0, 0, 0.38, 0.9) 0ms;
  transition-property: opacity;
  display: flex;
  flex-flow: row;
  overflow: hidden;
}
body.view-home {
  background-color: #090c25;
}
body.debug div[component] {
  border: 1px dashed #dd0000 !important;
}
body.project-crossulmde.view-home, body.project-crossulmde.view-dashboard {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/ulm-bg.jpg");
}
body.project-unicotskcz.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/praha-bg.jpg");
}
body.project-crosshrkcz.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/hradec_kralove-bg.jpg");
}
body.project-alamenvisk.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
}
body.project-crossesbdk.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/esbjerg-02-bg.jpg");
}
body.project-verdoalbdk.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/aalborg-bg.jpg");
}
body.project-krnappilcz.view-home, body.project-krnappilcz.view-dashboard {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/krnap-bg.jpg");
}
body.project-crosshavcz.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/havirov-bg.jpg");
}
body.project-crossvejdk.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/vejle-bg.jpg");
}
body.project-crossciscz.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/cross-02-bg.jpg");
}
body.project-crossrsdcz.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/RSD-bg.jpg");
}
body.project-dotswpregr.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/preveza-bg.jpg");
}
body.project-dotswkalgr.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/kalamaria-bg.jpg");
}
body.project-dotswpatgr.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/patras-bg.jpg");
}
body.project-crossegogr.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/egnatia-odos-bg.jpg");
}
body.project-crosspocvn.view-home {
  background-size: cover;
  background-image: url("~muklit/assets/images/cities/vietnam-bg.jpg");
}
body.project-etutpdubae.view-home, body.project-etutpdubae.view-dashboard {
  background-size: cover;
  background-color: #163788;
  background-image: url("~muklit/assets/images/cities/dubai-bg.jpg");
}