@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-user-menu {
  @include box-shadow;
  position: absolute;
  width: 288px;
  background-color: $color-field-01-dark;
  overflow: auto;
  z-index: $z-index-menu;

  div.user {
    @include display-flex-row;
    padding: 16px;
    border-bottom: 1px solid $color-ui-03-dark;

    div.avatar {
      @include size-width-height(40px, 40px);
      background-color: $color-ui-04-dark;
      margin-right: 16px;
      border-radius: 50%;
      overflow: hidden;

      img {
        @include size-width-height(100%, 100%);
      }
    }

    div.name {
      @include flex-auto-fill;
      align-items: center;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(16px, $color-text-01-dark);
        margin-bottom: 2px;
      }

      div.sublabel {
        @include font-proxima-regular;
        @include font-size-color(12px, $color-text-03-dark)
      }
    }
  }

  div.items {
    div.item {
      @include font-proxima-regular;
      @include cursor-pointer;
      @include transition-ui(background-color);
      position: relative;
      font-size: 14px;
      color: $color-text-02-dark;
      padding: 10px 32px 11px 16px;

      &:hover {
        background-color: $color-hover-dark;
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03-dark;
      }

      &-escalated {
        &:hover {
          color: $color-inverse-01 !important;
          background-color: $color-danger !important;
        }
      }
    }
  }
}
