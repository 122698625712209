// https://fonts.adobe.com/fonts/proxima-nova
// Proxima Nova Extrabold
@font-face {
  font-family: "Invipo Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff") format("woff");
}

// Proxima Nova Bold
@font-face {
  font-family: "Invipo Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff") format("woff");
}

// Proxima Nova Light
@font-face {
  font-family: "Invipo Light";
  src: url("../assets/fonts/ProximaNova-Light.woff") format("woff");
}

// Proxima Nova Medium
@font-face {
  font-family: "Invipo Medium";
  src: url("../assets/fonts/ProximaNova-Medium.woff") format("woff");
}

// Proxima Nova Regular
@font-face {
  font-family: "Invipo Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff") format("woff");
}

// Proxima Nova Semibold
@font-face {
  font-family: "Invipo Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff") format("woff");
}

// Proxima Nova Extrabold
@font-face {
  font-family: "Invipo Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff") format("woff");
}
