@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-filter-table {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  position: relative;
  overflow: hidden;

  > div.form {
    @include size-width-height(0, 100%);
    @include transition-ui("width, min-width");
    width: 0;
    overflow: hidden;

    form {
      width: 320px;
      box-sizing: border-box;
      padding: 32px 24px 0 24px;
    }

    &-toggled {
      @include size-width-height(320px, 100%);
    }
  }

  > div.data {
    @include flex-auto-fill;
    @include display-flex-column;
    height: 100%;
    position: relative;
    overflow: hidden;

    > div.filter {
      padding: 16px 24px 16px 24px;
    }

    > div.table {
      @include flex-auto-fill;
      padding: 0 24px 0 24px;
      overflow: auto;
    }

    > div.pagination {
      padding: 24px 24px 24px 24px;
    }
  }

  &-style-light {
    > div.form {
      //background-color: $color-field-01;
    }
  }

  &-style-dark {
    > div.form {
      //background-color: $color-field-01-dark;
    }
  }
}
