@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.partial-json {
  @include font-size-color(13px, $color-text-03-dark);
  line-height: 130%;
  font-family: "JetBrains Mono", Consolas, "Andale Mono", "Courier New", serif;
  white-space: pre;

  // JSON color scheme same for Light & Dark variant
  &-style-light,
  &-style-dark {
    span.key {
      color: #9876aa;
    }

    span.boolean {
      color: #cc7832;
    }

    span.string {
      color: #6a8759;
    }

    span.number {
      color: #6897bb;
    }
  }
}
