@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-docker-detail {
  @include size-width-height(600px, 100%);
  @include transition-ui(min-width);

  &-wide {
    min-width: 1200px;
  }

  > div.content-log {
    @include font-size-color(13px, $color-text-03-dark);
    font-family: Consolas, "Andale Mono", "Courier New", serif;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 8px 24px 24px 24px;

    span.timestamp {
      color: $color-interactive-02-dark;
    }

    span.message {
      color: $color-text-03-dark;
    }
  }
}
