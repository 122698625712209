@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-city-subdomain {
  @include cursor-pointer;
  position: relative;
  border: 2px solid $color-field-01;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 8px;
  background-color: $color-field-01;

  &:hover {
    background-color: $color-field-01;
  }

  div.header {
    @include display-flex-row;
    align-items: center;
    margin-bottom: 2px;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-text-01);
    }
  }

  div.kpis {
    @include cursor-pointer;
    display: none;
    flex-wrap: wrap;
    margin: 16px 0 24px 0;

    div.kpi {
      width: 50%;
      min-width: 50%;
      margin-bottom: 24px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      div.label {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
      }

      div.value {
        @include font-proxima-medium;
        @include font-size-color(14px, $color-text-01);
        margin: 2px 0;
      }

      div.description {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
      }
    }
  }

  div.metrics {
    display: none;
    flex-wrap: wrap;
    align-items: center;
    overflow: hidden;
    margin: 16px 0 16px -6px;

    div.tab {
      @include font-proxima-semi-bold;
      @include font-size-color(13px, $color-text-03);
      @include cursor-pointer;
      padding: 4px 6px;
      margin: 0 2px 4px 0;
      border-radius: 3px;

      &:hover {
        color: $color-text-01;
      }

      &-selected {
        color: $color-text-04;
        background-color: $color-ui-09;

        &:hover {
          color: $color-text-04;
        }
      }

      &-disabled {
        cursor: default !important;
        color: $color-text-06;

        &:hover {
          color: $color-text-06;
        }
      }
    }
  }

  div.bar {
    display: none;
    height: 12px;
    min-height: 12px;
    margin-top: 12px;
    background-color: $color-hover;
    border-radius: 6px;
    overflow: hidden;

    div.symbol {
      height: 100%;
      background-color: $color-ui-03;
    }
  }

  div.legend {
    display: none;
    margin-top: 12px;

    div.description {
      @include font-proxima-regular;
      @include font-size-color(13px, $color-text-03);
      margin-bottom: 8px;
    }

    div.row {
      @include display-flex-row;
      align-items: center;
      padding: 4px 0;
      box-sizing: border-box;

      div.symbol {
        @include size-width-height(10px, 10px);
        border-radius: 50%;
        margin-right: 8px;

        &-unknown {
            background-color: #aaaaaa;
        }
      }

      div.label {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-02);
        padding-right: 4px;

        &-link {
          &:hover {
            @include cursor-pointer;
          }
        }
      }

      div.count {
        @include flex-auto-fill;
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
        text-align: right;
        padding-right: 4px;
      }

      div.percent {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-03);
        padding-right: 4px;
        text-align: right;
      }

      &-cursor {
        @include cursor-pointer;
      }
    }

    div.range {
      @include display-flex-row;
      position: relative;
      height: 24px;
      align-items: center;
      user-select: none;

      div.values {
        @include display-flex-row;
        width: 100%;
        height: 24px;
        justify-content: space-between;
        align-items: center;
        background-color: #dddddd;
        padding: 0 8px;
        border-radius: 12px;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-field-02);
        }
      }

      div.average {
        position: absolute;
        left: 0;
        top: -4px;
        width: 2px;
        height: 32px;
        background-color: $color-ui-05;
      }
    }
  }

  div.actions {
    @include display-flex-row;
    text-align: right;
    justify-content: flex-end;
    padding: 12px 0 0 0;

    div.action {
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-interactive-01);
      @include transition-ui(color);
      @include cursor-pointer;
    }
  }

  &-selected {
    cursor: default !important;
    background-color: $color-field-01;

    div.kpis {
      @include display-flex-row;
      flex-wrap: wrap;
    }

    div.metrics {
      @include display-flex-row;
    }

    div.bar {
      @include display-flex-row;
    }

    div.legend {
      display: block;
    }
  }
}
