@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/animations";

div.invipo-item-geometry-detail {
  @include size-width-height(320px, 100%);
  box-sizing: border-box;
  border-radius: 6px;

  div.content {
    padding-bottom: 0;
  }

}
