@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-message-list {

  div.content {
    div.item {
      div.icon {
        &-r-w-w {
          background-color: #ffbb00;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-roadwork.svg");
          }
        }

        &-h-l-n {
          background-color: #ec383a;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-danger.svg");
          }
        }

        &-i-v-s {
          background-color: #47cff5;

          &:after {
            -webkit-mask-image: url("~muklit/assets/images/markers/road/24/24-markers-road-road_non_weather.svg");
          }
        }
      }
    }
  }

}
