@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-fleet-distance-tile {
    div.chart div.columns-30 div.column {
        width: 8px;
        min-width: 7px;
        margin-right: 2px;
    }
}
