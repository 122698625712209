@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-title-bar {
  @include size-width-height(100%, 40px);
  @include display-flex-row;
  align-items: center;

  > div.title {
    @include font-proxima-semi-bold;
    font-size: 19px;
    white-space: nowrap;
    margin-right: 24px;
  }

  > div.filter {
    @include flex-auto-fill;
    @include display-flex-row;
  }

  > div.spacer {
    @include flex-auto-fill;
  }

  &-style-light {
    > div.title {
      color: $color-text-02;
    }
  }

  &-style-dark {
    > div.title {
      color: $color-text-02-dark;
    }
  }
}
