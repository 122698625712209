@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-item-general {
  width: 724px;
  padding: 56px 32px 56px 32px;

  div.map {
    height: 218px;
    border-radius: 6px;
    background-color: $color-field-01;
    margin-bottom: 24px;

    > div {
      border-radius: 6px;
    }
  }

  div.title {
    @include display-flex-row;
    justify-content: space-between;
    padding: 8px 0 12px 0;

    div.label {
      @include font-proxima-semi-bold;
      @include font-size-color(16px, $color-text-03);
      @include flex-auto-fill;
    }

    div.edit {
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-interactive-01);
      @include cursor-pointer;
      padding: 4px 4px;
    }
  }

  div.description {
    @include font-proxima-regular;
    @include font-size-color(14px, $color-text-02);
    padding-bottom: 24px;
  }

  div.properties {
    @include display-flex-row;
    flex-wrap: wrap;
    background-color: $color-field-01;
    padding: 24px 24px 24px 24px;
    margin-bottom: 24px;
    border-radius: 6px;

    div.property {
      width: 50%;
      min-width: 50%;
      margin-bottom: 24px;

      &:nth-last-child(-n+2) {
        margin-bottom: 0;
      }

      div.label {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
      }

      div.value {
        @include font-proxima-medium;
        @include font-size-color(16px, $color-icon-01);

        &::after {
          content: " ";
        }

        a {
          color: $color-interactive-01;
        }
      }
    }
  }
}
