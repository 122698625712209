html, body {
  width: 100%;
  height: 100%;
}

body, h1, h2, h3, h4, h5, h6, p, ul, li, pre, button, input {
  padding: 0;
  margin: 0;
  border: 0;
}

h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}

table {
  border-spacing: 0;
}

th {
  text-align: left;
}

ul, li {
  list-style: none;
}

label {
  display: block;
}

@font-face {
  font-family: "Invipo Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff") format("woff");
}
@font-face {
  font-family: "Invipo Bold";
  src: url("../assets/fonts/ProximaNova-Bold.woff") format("woff");
}
@font-face {
  font-family: "Invipo Light";
  src: url("../assets/fonts/ProximaNova-Light.woff") format("woff");
}
@font-face {
  font-family: "Invipo Medium";
  src: url("../assets/fonts/ProximaNova-Medium.woff") format("woff");
}
@font-face {
  font-family: "Invipo Regular";
  src: url("../assets/fonts/ProximaNova-Regular.woff") format("woff");
}
@font-face {
  font-family: "Invipo Semibold";
  src: url("../assets/fonts/ProximaNova-Semibold.woff") format("woff");
}
@font-face {
  font-family: "Invipo Extrabold";
  src: url("../assets/fonts/ProximaNova-Extrabld.woff") format("woff");
}
.animation-fade-in-300 {
  opacity: 1 !important;
  animation: keyframes-fade-in-01 300ms;
}

.animation-fade-out-300 {
  opacity: 0 !important;
  animation: keyframes-fade-out-01 300ms;
}

@keyframes keyframes-fade-in-01 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes keyframes-fade-in-02 {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes keyframes-fade-in-03 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.35;
  }
}
@keyframes keyframes-fade-out-01 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #979797;
}

div.muklit-loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
div.muklit-loader div.loader-bar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 3px;
  overflow: hidden;
  z-index: 1;
}
div.muklit-loader div.loader-bar:after {
  content: "";
  display: block;
  position: relative;
  background: #008efa;
  width: 0;
  height: 100%;
  animation: keyframes-loader-loading 2400ms ease-in-out 400ms infinite alternate;
}
@keyframes keyframes-loader-loading {
  0% {
    left: -9.5%;
    width: 10%;
  }
  50% {
    width: 40%;
  }
  100% {
    left: 99.5%;
    width: 10%;
  }
}

div.muklit-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.35;
  animation: keyframes-fade-in-03 300ms;
}
@media print {
  div.muklit-overlay {
    display: none;
  }
}