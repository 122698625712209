@import "~muklit/styles/_positions.scss";
@import "~muklit/styles/_colors.scss";
@import "~muklit/styles/_mixins.scss";

div.invipo-area-form {
  @include size-width-height(1280px, 960px);
  @include position-center-optical;
  animation: keyframes-detail-slide-in 300ms;

  > div.content-map {
    padding: 0;
  }
}
