@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-user-create-form {
  @include size-width-height(648px, 696px);
  @include position-center-optical;

  > div.content {
    form {
      @include display-flex-row;

      div.fieldset {
        width: 288px;
      }

      div.fieldset-left {
        margin-right: 24px;
      }
    }
  }
}
