@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-message-sign-card {

  div.screens {
    padding: 0 24px 16px 24px;

    div.screen {
      margin-bottom: 8px;
      border: 4px solid $color-icon-01;

      img {
        display: block;
        width: 100%;
      }
    }
  }

}
