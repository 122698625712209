@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.partial-position-formatter {
  @include display-flex-row;
  align-items: center;

  div.position {
    @include font-proxima-regular;
    font-size: 14px;
  }

  div.center {
    @include size-width-height(24px, 24px);
    @include cursor-pointer;
    -webkit-mask-size: 24px 24px;
    -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-center.svg");
    margin-left: 12px;
  }

  &-style-light {
    div.position {
      color: $color-text-05;
    }

    div.center {
      background-color: $color-text-02;
    }
  }

  &-style-dark {
    div.position {
      color: $color-text-05-dark;
    }

    div.center {
      background-color: $color-text-02-dark;
    }
  }
}
