@import "../../styles/mixins";
@import "../../styles/colors";

div.invipo-design-map {
  position: relative;
  width: 100%;
  height: 100%;

  div.mapboxgl-canvas-container {
    canvas {
      outline: none;
    }
  }

  div.mapboxgl-control-container {
    a.mapboxgl-ctrl-logo, div.mapboxgl-ctrl-attrib {
      display: none;
    }
  }

  button.mapbox-gl-draw_trash {
    display: none;

    &.visible {
      display: block;
    }
  }

  div.placeholder-control {
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 1;
  }

}
