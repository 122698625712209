@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-text-area {

  div.label {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.helper {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.input {
    position: relative;
    margin-top: 4px;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    textarea {
      @include font-proxima-regular;
      display: block;
      width: 100%;
      box-sizing: border-box;
      font-size: 14px;
      outline: none;
      padding: 12px 16px;
      border: none;
      border-bottom: 1px solid transparent;
      border-radius: 0;
    }

    &:after {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 10px;
      right: 16px;
      -webkit-mask-size: 20px 20px;
      pointer-events: none;
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 12px;
    margin-top: 4px;
  }

  &-transparent {
    div.input {
      textarea {
        background-color: transparent !important;
      }
    }
  }

  &-invalid {
    div.input {

      textarea {
        padding-right: 46px;
      }

      &:after {
        content: "";
        -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-error.svg");
      }
    }
  }

  &-verified {
    div.input {
      textarea {
        padding-right: 46px;
      }

      &:after {
        content: "";
        -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-success.svg");
      }
    }
  }

  &-style-light {
    div.label {
      color: $color-text-02;
    }

    div.helper {
      color: $color-text-05;
    }

    div.input {
      border-color: $color-ui-04;

      textarea {
        color: $color-text-01;
        background-color: $color-field-01;

        &::placeholder {
          color: $color-text-03;
        }

        &:hover {
          border-color: $color-ui-04;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01 inset;
        }
      }
    }

    div.message {
      color: $color-text-05;
    }

    &.muklit-text-area-bright {
      div.input {
        textarea {
          background-color: $color-field-02;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active  {
            -webkit-text-fill-color: $color-text-02;
            -webkit-box-shadow: 0 0 0 1000px $color-field-02 inset;
          }
        }
      }
    }

    &.muklit-text-area-active {
      div.input {
        border-color: $color-interactive-01;

        textarea {
          border-color: $color-interactive-01;
        }

        &:hover {
          border-color: $color-interactive-01;
        }
      }
    }

    &.muklit-text-area-invalid {
      div.input {
        border-color: $color-support-01;

        textarea {
          border-color: $color-support-01;
        }

        &:after {
          background-color: $color-support-01;
        }

        &:hover {
          border-color: $color-support-01;
        }
      }

      div.message {
        color: $color-text-error;
      }
    }

    &.muklit-text-area-verified {
      div.input {
        border-color: $color-support-02;

        textarea {
          border-color: $color-support-02;
        }

        &:after {
          background-color: $color-support-02;
        }

        &:hover {
          border-color: $color-support-02;
        }
      }

      div.message {
        color: $color-support-02;
      }
    }

    &.muklit-text-area-disabled {
      div.label {
        color: $color-disabled-02;
      }

      div.helper {
        color: $color-disabled-02;
      }

      div.input {
        border-color: transparent;

        textarea {
          cursor: not-allowed;
          border-color: transparent;
          color: $color-disabled-02;

          &::placeholder {
            color: $color-disabled-02;
          }
        }

        &:hover {
          border-color: transparent;
        }
      }

      div.messae {
        color: $color-disabled-02;
      }
    }
  }

  &-style-dark {
    div.label {
      color: $color-text-02-dark;
    }

    div.helper {
      color: $color-text-05-dark;
    }

    div.input {
      border-color: $color-ui-04-dark;

      textarea {
        color: $color-text-01-dark;
        background-color: $color-field-01-dark;

        &::placeholder {
          color: $color-text-03-dark;
        }

        &:hover {
          border-color: $color-ui-04-dark;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01-dark;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01-dark inset;
        }
      }
    }

    div.message {
      color: $color-text-05-dark;
    }

    &.muklit-text-area-bright {
      div.input {
        textarea {
          background-color: $color-field-02-dark;

          &:-webkit-autofill,
          &:-webkit-autofill:hover,
          &:-webkit-autofill:focus,
          &:-webkit-autofill:active  {
            -webkit-text-fill-color: $color-text-02-dark;
            -webkit-box-shadow: 0 0 0 1000px $color-field-02-dark inset;
          }
        }
      }
    }

    &.muklit-text-area-active {
      div.input {
        border-color: $color-interactive-01-dark;

        textarea {
          border-color: $color-interactive-01-dark;
        }

        &:hover {
          border-color: $color-interactive-01-dark;
        }
      }
    }

    &.muklit-text-area-invalid {
      div.input {
        border-color: $color-support-01-dark;

        textarea {
          border-color: $color-support-01-dark;
        }

        &:after {
          background-color: $color-support-01-dark;
        }

        &:hover {
          border-color: $color-support-01-dark;
        }
      }

      div.message {
        color: $color-text-error-dark;
      }
    }

    &.muklit-text-area-verified {
      div.input {
        border-color: $color-support-02-dark;

        textarea {
          border-color: $color-support-02-dark;
        }

        &:after {
          background-color: $color-support-02-dark;
        }

        &:hover {
          border-color: $color-support-02-dark;
        }
      }

      div.message {
        color: $color-support-02-dark;
      }
    }

    &.muklit-text-area-disabled {
      div.label {
        color: $color-disabled-02-dark;
      }

      div.helper {
        color: $color-disabled-02-dark;
      }

      div.input {
        border-color: transparent;

        textarea {
          cursor: not-allowed;
          border-color: transparent;
          color: $color-disabled-02-dark;

          &::placeholder {
            color: $color-disabled-02-dark;
          }
        }

        &:hover {
          border-color: transparent;
        }
      }

      div.messae {
        color: $color-disabled-02-dark;
      }
    }
  }

}
