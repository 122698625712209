// Full viewport width and height
html, body {
  width: 100%;
  height: 100%;
}

// Reset padding, margin and border for major elements
body, h1, h2, h3, h4, h5, h6, p, ul, li, pre, button, input {
  padding: 0;
  margin: 0;
  border: 0;
}

// Reset font weight
h1, h2, h3, h4, h5, h6, th {
  font-weight: normal;
}

// Avoid table border spacing
table {
  border-spacing: 0;
}

// Reset default text align
th {
  text-align: left;
}

// Reset list symbols
ul, li {
  list-style: none;
}

// Reset label floating
label {
  display: block;
}
