@import "~muklit/styles/_colors.scss";
@import "~muklit/styles/_mixins.scss";

div.invipo-map-weather {
  @include transition-ui(all, 300ms);
  background-color: $color-ui-background;
  user-select: none;
  overflow: hidden;
  border-radius: 4px;

  &:hover {
    @include box-shadow;

    div.header {
      div.label {
        display: block;
      }

      div.temperature {
        margin-right: 24px;
      }
    }

    div.content {
      display: block;
    }
  }

  div.header {
    @include display-flex-row;
    align-items: center;
    padding: 8px 0;

    div.label {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-text-01);
      display: none;
      margin-left: 24px;
    }

    div.temperature {
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-02);
      text-align: center;
      white-space: nowrap;
      margin-right: 8px;
    }

    div.icon {
      @include size-width-height(24px, 24px);
      background-color: $color-icon-02;
      margin-left: 8px;
      -webkit-mask-size: 24px 24px;

      &.condition {
        &-thunderstorm {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-11d.svg");
        }

        &-drizzle,
        &-rain {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-09d.svg");
        }

        &-snow {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-13d.svg");
        }

        &-clear {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-01d.svg");
        }

        &-clouds {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-03d.svg");
        }

        &-mist,
        &-smoke,
        &-haze,
        &-dust,
        &-fog,
        &-sand,
        &-dust,
        &-ash {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-50d.svg");
        }

        &-squall,
        &-tornado {
          background-color: transparent;
        }
      }
    }
  }

  div.content {
    display: none;
    box-sizing: border-box;
    padding: 8px 24px 16px 24px;

    div.history {
      margin-bottom: 16px;

      div.label {
        @include display-flex-column;
        width: 40px;
        height: 48px;
        justify-content: space-between;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);

          &:last-child {
            margin-bottom: -6px;
          }
        }
      }

      div.columns {
        @include display-flex-row;
        height: 48px;
        min-height: 48px;
        background-color: $color-hover-dark;

        div.column {
          position: relative;
          width: 4px;
          min-width: 4px;
          margin-right: 2px;
          background-color: $color-ui-08;
          overflow: hidden;

          &:last-child {
            margin-right: 0;
          }

          div.bar {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: $color-ui-04;
          }
        }
      }

      div.range {
        @include display-flex-row;
        position: relative;
        justify-content: space-between;
        margin-top: 4px;
        margin-left: 40px;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);

          &-fixed {
            width: 40px;
            overflow: hidden;
          }
        }
      }
    }

    div.sun {
      @include display-flex-row;
      justify-content: space-between;

      div.icon {
        @include size-width-height(24px, 24px);
        margin-right: 8px;
        background-color: $color-icon-02;
        -webkit-mask-size: 24px 24px;

        &.sunrise {
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-sunrise.svg");
        }

        &.sunset {
          margin-left: auto;
          -webkit-mask-image: url("~muklit/assets/images/icons/meteo/24/24-meteo-sunset.svg");
        }
      }

      div.label {
        @include display-flex-row;
        @include font-proxima-regular;
        align-items: center;
        font-size: 14px;
      }
    }
  }
}
