@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-traffic-volume-insight {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  div.series {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 24px 16px 0 16px;
    overflow: auto;

    div.data {
      background-color: $color-ui-01;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;

      div.header {
        @include display-flex-row;
        height: 40px;
        min-height: 40px;
        align-items: center;
        margin-bottom: 8px;

        div.line {
          @include size-width-height(20px, 4px);
          background-color: $color-text-05;
          margin-right: 8px;
        }

        div.title {
          @include flex-auto-fill;
          @include font-proxima-semi-bold;
          font-size: 12px;
          color: $color-text-02;
          text-transform: uppercase;
          margin-right: 24px;
        }
      }
    }

    div.new {
      @include font-proxima-semi-bold;
      @include cursor-pointer;
      font-size: 14px;
      text-align: right;
      color: $color-interactive-01;
      padding: 0 12px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  div.insight {
    @include flex-auto-fill;
    @include display-flex-column;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px 24px 24px;
    overflow: hidden;

    div.header {
      @include display-flex-row;
      height: 40px;
      min-height: 40px;
      align-items: center;
      margin-bottom: 8px;

      div.title {
        @include flex-auto-fill;
        @include font-proxima-semi-bold;
        font-size: 19px;
        color: $color-text-02;
        margin-right: 24px;
      }
    }

    div.filter {
      @include display-flex-row;
      height: 32px;
      min-height: 32px;
      align-items: center;
      margin-bottom: 16px;
    }

    div.navigation {
      @include display-flex-row;
      align-items: center;
      margin-bottom: 16px;

      div.group {
        @include font-proxima-regular;
        @include cursor-pointer;
        @include transition-ui(color);
        font-size: 14px;
        color: $color-text-03;
        margin-right: 32px;

        &:hover {
          color: $color-text-02;
        }

        &-selected {
          @include font-proxima-semi-bold;
          color: $color-text-01;
        }
      }

      div.spacer {
        @include flex-auto-fill;
      }
    }

    div.chart {
      @include flex-auto-fill;
      overflow: auto;
      margin-bottom: 16px;
    }

    div.table {
      @include flex-auto-fill;
      overflow: auto;
    }

    div.legend {
      @include display-flex-row;
      padding-left: 16px;
      margin-bottom: 24px;

      div.symbol {
        @include display-flex-row;
        align-items: center;
        margin-right: 16px;
        overflow: hidden;

        div.line {
          @include size-width-height(20px, 4px);
          background-color: $color-text-05;
          margin-right: 8px;
        }

        div.label {
          @include font-proxima-regular;
          color: $color-text-01;
          font-size: 12px;
        }
      }
    }
  }

  &-overlay {
    @include size-width-height(1920px, 1080px);
    @include position-center-optical;
  }
}
