@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-violation-report {
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  animation: keyframes-detail-grow-in 300ms;
  background-color: $color-field-01;

  div.content {
    scrollbar-gutter: stable;

    div.offence {
      width: 720px;
      margin: 0 auto;
      box-sizing: border-box;
      padding: 32px 24px;
      background-color: $color-ui-background;
      border-radius: 6px;

      p {
        @include font-proxima-regular;
        @include font-size-color(14px, $color-text-01);
        padding-bottom: 16px;
      }
    }
  }

  @media print{
    all: unset !important;

    > div.header {
      display: none;
    }

    > div.content {
      width: auto;
      min-width: auto;
      padding: 0;
      margin: 0;

      div.offence {
        width: auto;
        min-width: auto;
        background-color: unset;
        padding: 0;
        margin: 0;
      }
    }

    > div.control {
      display: none;
    }
  }
}
