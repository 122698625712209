@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-panel {
  position: relative;
  overflow: auto;

  > div.content {
    width: 720px;
    min-width: 720px;
    padding: 24px 32px 56px 32px;
    margin: 0 auto;

    > div.title {
      @include display-flex-row;
      align-items: center;
      margin-bottom: 42px;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(19px, $color-ui-05);
        @include flex-auto-fill;
      }
    }

    > div.section {
      @include display-flex-row;
      justify-content: space-between;
      break-after: avoid;
      margin-bottom: 18px;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(16px, $color-ui-05);
        @include flex-auto-fill;
      }

      div.action {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-interactive-01);
        @include cursor-pointer;
        padding: 2px 4px 0 4px;
      }
    }

    > div.map {
      height: 240px;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: $color-field-01;
      margin-bottom: 24px;
      overflow: hidden;

      &-tall {
        height: 484px;
      }
    }

    > div.form {
      background-color: $color-field-01;
      padding: 24px 24px 24px 24px;
      break-inside: avoid;
      margin-bottom: 24px;
      border-radius: 6px;
    }

    > div.table {
      padding-bottom: 32px;
      break-inside: avoid;

      table {
        width: 100%;

        tr {

          &:last-child {
            td {
              border-bottom: none;
            }
          }

          th {
            @include font-proxima-semi-bold;
            @include font-size-color(13px, $color-text-03);
            cursor: default;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            box-sizing: border-box;
            padding: 8px 12px 8px 12px;
            border-bottom: 1px solid $color-ui-03;

            &:first-child {
              padding-left: 0;
            }

            div {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          td {
            border-bottom: 1px solid $color-ui-03;
            padding: 8px 12px 8px 12px;

            &:first-child {
              padding-left: 0;
            }

            &.value {
              @include font-proxima-regular;
              @include font-size-color(14px, $color-text-01);

              &-label {
                @include font-proxima-regular;
                @include font-size-color(14px, $color-text-03);
              }

              &-bold {
                @include font-proxima-medium;
              }

              &-left {
                text-align: left;
              }

              &-center {
                text-align: center;
              }

              &-right {
                text-align: right;
              }
            }

            a {
              @include font-proxima-semi-bold;
              color: $color-interactive-01;
            }
          }
        }
      }
    }

    > div.chart {
      margin-bottom: 8px;
      overflow: auto;
      break-inside: avoid;

      div.columns {
        @include display-flex-row;
        background-color: $color-hover-dark;
        align-items: center;

        div.current {
          @include display-flex-row;
          align-items: center;
          justify-content: space-between;

          div.label {
            @include font-proxima-regular;
            @include font-size-color(13px, $color-text-03);
          }

          div.value {
            @include font-proxima-medium;
            @include font-size-color(14px, $color-ui-05);
            margin-right: 8px;
          }
        }

        div.column {
          @include display-flex-column;
          position: relative;
          height: 48px;
          min-height: 48px;
          background-color: $color-ui-08;
          overflow: hidden;
          justify-content: end;

          &:first-child {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          &:last-child {
            border-top-right-radius: 6px;
            border-bottom-right-radius: 6px;
          }

          &:last-child {
            margin-right: 0;
          }

          div.label {
            @include font-proxima-regular;
            @include font-size-color(11px, $color-text-03);
            text-align: center;
            margin: 2px 0;

            &-rotated {
              transform: rotate(-90deg);
            }
          }

          div.bar {
            width: 100%;
            background-color: $color-ui-04;
          }

          &-transpartent {
            background-color: unset;
          }
        }

        &-4 {
          div.column {
            width: 176px;
            min-width: 176px;
            margin-right: 4px;
          }
        }

        &-5 {
          div.column {
            width: 140px;
            min-width: 140px;
            margin-right: 4px;
          }
        }

        &-24 {
          div.column {
            width: 26px;
            min-width: 26px;
            margin-right: 4px;
          }
        }

        &-30 {
          div.column {
            width: 20px;
            min-width: 20px;
            margin-right: 4px;
          }
        }

        &-48 {
          div.column {
            width: 12px;
            min-width: 12px;
            margin-right: 3px;
          }
        }

        &-96 {
          div.column {
            width: 4px;
            min-width: 4px;
            margin-right: 2px;
          }
        }

        &-144 {
          div.column {
            width: 3px;
            min-width: 3px;
            margin-right: 2px;
          }
        }

        &-small {
          div.column {
            height: 24px;
            min-height: 24px;
          }
        }

        &-medium {
          div.column {
            height: 128px;
            min-height: 64px;
          }
        }

        &-tall {
          div.column {
            height: 256px;
            min-height: 256px;
          }
        }

        &-selectable {
          div.column {
            &:hover {
              @include cursor-pointer;
              //background-color: $color-ui-07;
            }
          }
        }
      }

      div.range {
        @include display-flex-row;
        position: relative;
        margin: 4px 0 16px 0;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);

          &-fixed {
            width: 40px;
            overflow: hidden;
          }

          &-bold {
            @include font-proxima-semi-bold;
          }

          &-rotated {
            transform: rotate(90deg);
            white-space: nowrap;
          }
        }

        &-4 {
          div.value {
            width: 180px;
            min-width: 180px;
            text-align: center;
          }
        }

        &-5 {
          div.value {
            width: 144px;
            min-width: 144px;
            text-align: center;
          }
        }

        &-24 {
          div.value {
            width: 30px;
            min-width: 30px;
            text-align: center;
          }
        }

        &-48 {
          div.value {
            width: 15px;
            min-width: 15px;
            text-align: center;
          }
        }
      }
    }

    > div.legend {
      @include display-flex-row;
      break-before: avoid;
      margin-bottom: 32px;

      div.symbol {
        @include display-flex-row;
        align-items: center;
        margin-right: 16px;
        overflow: hidden;

        div.line {
          @include size-width-height(20px, 4px);
          background-color: $color-text-05;
          margin-right: 8px;
        }

        div.label {
          @include font-proxima-regular;
          color: $color-text-01;
          font-size: 12px;
        }
      }
    }

    > div.properties {
      @include flex-auto-fill;
      background-color: $color-field-01;
      break-inside: avoid;
      padding: 24px 24px 12px 24px;
      margin-bottom: 24px;
      border-radius: 6px;

      div.property {
        margin-bottom: 16px;

        div.label {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
          margin-bottom: 2px;
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(14px, $color-ui-05);

          &::after {
            content: "";
          }

          a {
            @include font-proxima-semi-bold;
            color: $color-interactive-01;
          }
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }
      }

      div.note {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03);
        margin-bottom: 16px;
      }

      &-full {
        display: block;

        div.property {
          width: 100%
        }
      }

      &-half {
        @include display-flex-row;
        flex-wrap: wrap;

        div.property {
          width: 50%;
        }
      }

      &-third {
        @include display-flex-row;
        flex-wrap: wrap;

        div.property {
          width: 33.3%;
        }
      }

      &-fourth {
        @include display-flex-row;
        flex-wrap: wrap;

        div.property {
          width: 25%;
        }
      }

      &-fifth {
        @include display-flex-row;
        flex-wrap: wrap;

        div.property {
          width: 20%;
        }
      }
    }

    > div.kpis {
      @include flex-auto-fill;
      break-inside: avoid;
      margin-bottom: 24px;

      div.kpi {
        background-color: $color-field-01;
        box-sizing: border-box;
        padding: 24px 24px 24px 24px;
        margin-bottom: 16px;
        border-radius: 6px;

        div.label {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
          margin-bottom: 2px;
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(16px, $color-text-01);

          &::after {
            content: "";
          }

          a {
            @include font-proxima-semi-bold;
            color: $color-interactive-01;
          }
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
          margin-top: 2px;
        }
      }

      &-full {
        display: block;

        div.kpi {
          width: 100%
        }
      }

      &-half {
        @include display-flex-row;
        justify-content: space-between;
        flex-wrap: wrap;

        div.kpi {
          width: 50%;
        }
      }

      &-third {
        @include display-flex-row;
        justify-content: space-between;
        flex-wrap: wrap;

        div.kpi {
          width: calc(33.3% - 4px);
        }
      }

      &-fourth {
        @include display-flex-row;
        justify-content: space-between;
        flex-wrap: wrap;

        div.kpi {
          width: 25%;
        }
      }

      &-fifth {
        @include display-flex-row;
        justify-content: space-between;
        flex-wrap: wrap;

        div.kpi {
          width: 20%;
        }
      }
    }

    &-wide {
      width: 840px;
      min-width: 840px;
    }

    &-full {
      width: 100%;
      min-width: 100%;
      box-sizing: border-box;
      margin: 0;
    }
  }

  @media print {
    display: block;
    height: auto;
    overflow: unset;

    > div.content {
      width: auto;
      min-width: auto;
      padding: 0;
      margin: 0;

      > div.form {
        display: none;
      }

      > div.section {
        div.action {
          display: none;
        }
      }
    }
  }
}
