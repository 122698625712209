@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-battery-status-browser {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  position: relative;
  overflow: hidden;

  div.form {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 32px 24px 0 24px;
    border-right: 1px solid $color-ui-03;
  }

  div.data {
    @include flex-auto-fill;
    height: 100%;
    overflow: hidden;
  }
}
