@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-severity-formatter {
  @include display-flex-row;
  align-items: center;

  div.severity {
    @include size-width-height(12px, 12px);
    position: relative;
    margin: 0 4px 0 0;
    border-radius: 50%;
    background-color: #dddddd;

    &:after {
      @include font-proxima-extra-bold;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      font-size: 11px;
      text-align: center;
      color: $color-ui-background-dark;
    }

    &-info {
      background-color: transparent;

      &:after {
        content: "";
      }
    }

    &-warn {
      background-color: $color-severity-warn;

      &:after {
        content: "!";
      }
    }
    &-error {
      background-color: $color-severity-error;

      &:after {
        content: "!";
      }
    }

  }

  div.text {
    @include flex-auto-fill;
    @include font-proxima-regular;
    font-size: 14px;
  }

  &-style-light {
    div.text {
      color: $color-text-02;
    }
  }

  &-style-dark {
    div.text {
      color: $color-text-02-dark;
    }
  }
}
