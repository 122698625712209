@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-ticket-board {
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  div.column {
    @include display-flex-column;
    @include size-width-height(270px, 100%);
    margin-left: 24px;

    div.header {
      @include display-flex-row;
      align-items: center;
      margin-bottom: 16px;
      border-bottom: 1px solid $color-ui-06;

      div.name {
        @include font-proxima-semi-bold;
        @include font-size-color(16px, $color-ui-05);
        height: 24px;
        margin: 4px 8px 2px 0;
      }

      div.count {
        @include font-proxima-regular;
        @include font-size-color(12px, $color-text-01);
        width: 24px;
        height: 24px;
        box-sizing: border-box;
        background-color: $color-field-01;
        text-align: center;
        padding-top: 4px;
        margin-right: auto;
        border-radius: 12px;
      }
    }

    div.tickets {
      @include flex-auto-fill;
      @include transition-ui(background-color);
      overflow: auto;

      div.ticket {
        @include cursor-pointer;
        //@include transition-ui(background-color);
        position: relative;
        background-color: $color-ui-01;
        padding: 12px;
        margin-bottom: 8px;
        border-radius: 6px;

        &:hover {
          background-color: $color-ui-03;
        }

        div.title {
          @include font-proxima-semi-bold;
          @include font-size-color(16px, $color-text-01);
          margin-bottom: 4px;
        }

        div.subtitle {
          @include font-proxima-regular;
          @include font-size-color(14px, $color-text-03);
          margin-bottom: 16px;
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(14px, $color-text-01);
          min-height: 20px;
          max-height: 68px;
          overflow: hidden;
          margin-bottom: 20px;
        }

        div.timestamp {
          @include font-proxima-regular;
          @include font-size-color(12px, $color-text-05);
        }

        div.assignee {
          @include font-proxima-regular;
          @include font-size-color(12px, $color-text-05);
          position: absolute;
          right: 12px;
          bottom: 12px;
        }

        &-selected {
          //background-color: $color-ui-03;
          background-color: $color-highlight-02;

          &:hover {
            background-color: $color-highlight-02;
          }
        }
      }
    }

    &-selected {
      div.header {
        div.name {
          @include font-proxima-extra-bold;
        }
      }

      div.tickets {
        //background-color: $color-field-01;
      }
    }
  }
}
