@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";
@import "../../../styles/colors";

div.invipo-city-card {
  @include display-flex-column;
  @include box-shadow;
  @include transition-ui("top, height", 100ms);
  position: absolute;
  width: 336px;
  background-color: $color-field-04;
  border-radius: 4px;
  z-index: $z-index-card;
  overflow: auto;
  animation: keyframes-card-fade-in 400ms;

  div.header {
    @include display-flex-row;
    position: relative;
    align-items: flex-start;
    user-select: none;
    padding: 16px 16px 16px 24px;

    div.title {
      @include flex-auto-fill;
      margin-top: 8px;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(19px, $color-ui-05);
        margin-bottom: 4px;
      }

      div.sublabel {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-02);
      }
    }

    &-detail {
      @include cursor-pointer;
      @include transition-ui(background-color);

      &:hover {
        background-color: $color-hover;
      }
    }

    &-border {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        width: 100%;
        height: 1px;
        background-color: $color-shadow-02;
      }
    }
  }

  div.content {
    @include flex-auto-fill;
    @include display-flex-column;
    scrollbar-gutter: stable;
    overflow: auto;
    padding: 16px 0 0 0;

    div.alerts {
      padding: 8px 12px;
      margin: 0 10px 24px 10px;
      border: 2px solid rgba($color-support-01, 0.15);
      border-radius: 4px;

      &:empty {
        display: none;
      }
    }

    div.kpis {
      @include display-flex-row;
      flex-wrap: wrap;
      padding: 0 24px 16px 24px;

      div.kpi {
        width: 50%;
        min-width: 50%;
        margin-bottom: 24px;

        &:nth-last-child(-n+2) {
          margin-bottom: 0;
        }

        div.label {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(14px, $color-text-01);
          margin: 2px 0;
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
        }
      }
    }

    div.snapshot {
      @include size-width-height(288px, 162px);
      background-color: $color-ui-08;
      margin: 0 24px 16px 24px;
      border-radius: 4px;

      img {
        @include cursor-pointer;
        display: block;
        width: 100%;
        overflow: hidden;
        border-radius: 4px;
      }
    }

    div.history {
      @include flex-auto-fill;
      padding: 0 20px 16px 24px;

      div.label {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-02);
        margin-bottom: 8px;
      }

      div.columns {
        @include display-flex-row;
        height: 48px;
        min-height: 48px;
        overflow: hidden;

        div.column {
          position: relative;
          width: 4px;
          min-width: 4px;
          margin-right: 2px;
          background-color: $color-ui-08;
          overflow: hidden;

          &:last-child {
            margin-right: 0;
          }

          div.bar {
            position: absolute;
            bottom: 0;
            width: 100%;
            background-color: $color-ui-04;
          }
        }

        &-small {
          height: 12px;
          min-height: 12px;
        }

        &-medium {
          height: 24px;
          min-height: 24px;
        }
      }

      div.range {
        @include display-flex-row;
        position: relative;
        justify-content: space-between;
        margin-top: 4px;

        div.value {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);

          &-fixed {
            width: 48px;
            overflow: hidden;
            white-space: nowrap;
          }
        }
      }
    }

    div.properties {
      @include flex-auto-fill;
      padding: 0 24px 16px 24px;

      div.property {
        @include display-flex-row;
        flex-wrap: wrap;
        align-items: center;
        border-bottom: 1px solid $color-ui-07;
        padding: 10px 0;

        &:last-child {
          border-bottom: none;
        }

        div.symbol {
          @include size-width-height(10px, 10px);
          border-radius: 50%;
          background-color: #aaaaaa;
          margin-right: 8px;

          &-traffic-camera {
            background-color: $color-domain-traffic;
          }

          &-parking-lot,
          &-parking-sign {
            background-color: $color-domain-parking;
          }

          &-energy-meter,
          &-street-light-controller {
            background-color: $color-domain-energy;
          }

          &-bluetooth-detector,
          &-security-camera,
          &-radar {
            background-color: $color-domain-safety;
          }

          &-status-ok {
            background-color: $color-status-ok;
          }

          &-status-warn {
            background-color: $color-status-warn;
          }

          &-status-error {
            background-color: $color-status-error;
          }

          &-status-unreachable {
            background-color: $color-status-unreachable;
          }

          &-status-disconnected {
            background-color: $color-status-disconnected;
          }
        }

        div.label {
          @include flex-auto-fill;
          @include font-proxima-regular;
          @include font-size-color(14px, $color-text-03);
          @include transition-ui(color);
          margin-right: 16px;
        }

        div.value {
          @include font-proxima-medium;
          @include font-size-color(14px, $color-icon-01);
          text-align: right;
        }

        div.bar {
          @include size-width-height(100%, 8px);
          background-color: $color-ui-07;
          margin-top: 6px;
          border-radius: 4px;

          div.left {
            height: 100%;
            background-color: $color-text-01;
            border-radius: 4px;
          }
        }

        &-small {
          padding: 6px 0;
        }

        &-block {
          display: block;

          div.label {
            margin-bottom: 6px;
            text-align: left;
          }

          div.value {
            text-align: left;
          }
        }

        &-link {
          @include cursor-pointer;

          &:hover {
            div.symbol {
              @include transition-ui(transform);
              transform: scale(1.2);
            }
          }
        }
      }
    }

    div.actions {
      @include display-flex-row;
      text-align: right;
      justify-content: flex-end;
      padding: 16px 24px 16px 24px;

      div.action {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, $color-interactive-01);
        @include transition-ui(color);
        @include cursor-pointer;
        margin-left: 16px;
      }
    }
  }

  &-style-light {
    background-color: $color-field-04;

    div.header {
      div.title {
        div.label {
          color: $color-ui-05;
        }

        div.sublabel {
          color: $color-text-02;
        }
      }

      &-detail {
        &:hover {
          background-color: $color-hover;
        }
      }

      &-border {
        &:after {
          background-color: $color-shadow-02;
        }
      }
    }

    div.content {
      div.alerts {
        border: 2px solid rgba($color-support-01, 0.15);
      }

      div.kpis {
        div.kpi {
          div.label {
            color: $color-text-03;
          }

          div.value {
            color: $color-text-01;
          }

          div.description {
            color: $color-text-03;
          }
        }
      }

      div.history {
        div.label {
          color: $color-text-02;
        }

        div.columns {
          div.column {
            background-color: $color-ui-08;

            div.bar {
              background-color: $color-ui-04;
            }
          }
        }

        div.range {
          div.value {
            color: $color-text-03;
          }
        }
      }

      div.properties {
        div.property {
          border-bottom: 1px solid $color-ui-07;

          div.label {
            color: $color-text-03;
          }

          div.value {
            color: $color-icon-01;
          }

          div.bar {
            background-color: $color-ui-07;

            div.left {
              background-color: $color-text-01;
            }
          }
        }
      }

      div.actions {
        div.action {
          color: $color-interactive-01;
        }
      }
    }
  }

  &-style-dark {
    background-color: $color-field-04-dark;

    div.header {
      div.title {
        div.label {
          color: $color-ui-05-dark;
        }

        div.sublabel {
          color: $color-text-02-dark;
        }
      }

      &-detail {
        &:hover {
          background-color: $color-hover-dark;
        }
      }

      &-border {
        &:after {
          background-color: $color-shadow-02-dark;
        }
      }
    }

    div.content {
      div.alerts {
        border: 2px solid rgba($color-support-01-dark, 0.15);
      }

      div.kpis {
        div.kpi {
          div.label {
            color: $color-text-03-dark;
          }

          div.value {
            color: $color-text-01-dark;
          }

          div.description {
            color: $color-text-03-dark;
          }
        }
      }

      div.history {
        div.label {
          color: $color-text-02-dark;
        }

        div.columns {
          div.column {
            background-color: $color-ui-08-dark;

            div.bar {
              background-color: $color-ui-04-dark;
            }
          }
        }

        div.range {
          div.value {
            color: $color-text-03-dark;
          }
        }
      }

      div.properties {
        div.property {
          border-bottom: 1px solid $color-ui-07-dark;

          div.label {
            color: $color-text-03-dark;
          }

          div.value {
            color: $color-icon-01-dark;
          }

          div.bar {
            background-color: $color-ui-07-dark;

            div.left {
              background-color: $color-text-01-dark;
            }
          }
        }
      }

      div.actions {
        div.action {
          color: $color-interactive-01-dark;
        }
      }
    }
  }

  &-dragging {
    transition: none;
  }

  @keyframes keyframes-card-fade-in {
    0% {
      opacity: 0;
      transform: translateY(8px);
    }
    50% {
      opacity: 0;
      transform: translateY(8px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
