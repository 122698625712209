@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../../styles/colors";

div.invipo-connector-browser {
  @include flex-auto-fill;
  @include display-flex-column;
  position: relative;
  height: 100%;
  overflow: hidden;
  background-color: $color-ui-background-dark;

  div.header {
    @include display-flex-row;
    height: 40px;
    box-sizing: border-box;
    align-items: center;
    margin: 16px 24px 16px 24px;

    div.title {
      @include font-proxima-semi-bold;
      color: $color-text-02-dark;
      font-size: 19px;
    }
  }

  div.connectors {
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    padding: 0 24px;

    div.connector {
      @include display-flex-column;
      @include cursor-pointer;
      @include transition-ui(background-color);
      width: 228px;
      height: 360px;
      background-color: $color-field-01-dark;
      padding: 12px;
      margin: 0 16px 16px 0;
      border-radius: 6px;

      > div.status {
        margin-bottom: 8px;
      }

      > div.availability {
        margin-bottom: 16px;
      }

      > div.items {
        @include flex-auto-fill;
        overflow: auto;
        margin-bottom: 16px;

        div.partial-status-formatter {
          margin-bottom: 2px;
        }
      }

      > div.note {
        @include font-proxima-regular;
        font-size: 14px;
        color: $color-text-03-dark;
      }

      &-selected,
      &:hover {
        background-color: $color-hover-dark;
      }
    }
  }
}
