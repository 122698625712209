@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-map-marker {
  @include transition-ui(opacity);
  position: absolute;
  cursor: default;
  top: 0;
  left: 0;
  opacity: 1;
  z-index: 2;
  will-change: transform;
}
