@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-area-manager {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-ui-background;

  > div.form {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 32px 24px 0 24px;
    border-right: 1px solid $color-ui-01;
    overflow: hidden;
  }

  > div.content {
    @include flex-auto-fill;
    @include display-flex-column;
    height: 100%;
    overflow: hidden;

    > div.table {
      height: 708px;
      overflow: hidden;
    }

    > div.map {
      @include flex-auto-fill;
      min-height: 240px;
      overflow: hidden;
    }
  }
}
