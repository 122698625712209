@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-sign-info-line-input {
    @include display-flex-row;
    flex-wrap: wrap;
    background-color: $color-ui-08;
    border-radius: 6px;
    padding: 24px 12px 0 12px;
    position: relative;
    margin: 0 -12px 4px -12px;

    // &-removable {
    //     padding-top: 13+20px;
    // }

    div.icon-remove {
        @include cursor-pointer;
        @include size-width-height(20px, 20px);
        position: absolute;
        top: 13px;
        right: 13px;
        background-color: $color-icon-05;
        -webkit-mask-image: url("~muklit/assets/images/icons/notification/20/20-notification-disabled.svg");
    }

    .muklit-select, .muklit-text-input {
        margin-right: 24px;
        margin-bottom: 24px;
    }
}