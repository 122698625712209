@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-wind-measurement {
  @include display-flex-row;
  align-items: center;
  justify-content: right;

  div.direction {
    @include size-width-height(15px, 15px);
    background-color: $color-text-02;
    margin-right: 4px;
    -webkit-mask-size: 15px 15px;
    -webkit-mask-image: url("~muklit/assets/images/icons/tools/15/15-tools-arrow_up.svg");
  }

  div.value {
    white-space: nowrap;
  }
}
