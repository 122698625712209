@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-traffic-queue-browser {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  position: relative;
  overflow: hidden;

  div.form {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 32px 24px 0 24px;
    border-right: 1px solid $color-ui-03;

    div.fieldset-length {
      @include display-flex-row;
      align-items: flex-end;
      flex-wrap: wrap;

      div.field {
        width: 50%;
        min-width: 50%;
      }
    }
  }

  div.data {
    @include flex-auto-fill;
    height: 100%;
    overflow: hidden;
  }
}

@media print {

  div.invipo-vehicle-browser {
    display: none;
  }

}
