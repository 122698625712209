@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

$image-width: 290px;
$image-height: 218px;

div.invipo-wall-2droad {
  @include flex-auto-fill;
  position: relative;
  height: 100%;
  background-color: #000000;
  overflow: hidden;

  div.images {
    @include display-flex-row;
    flex-wrap: wrap;
    padding: 24px 0 0 24px;

    div.image {
      width: $image-width;
      margin: 0 20px 42px 0;

      div.title {
        @include display-flex-row;
        align-items: center;
        margin-bottom: 8px;

        div.label {
          @include flex-auto-fill;
          @include font-proxima-semi-bold;
          font-size: 19px;
          color: #ffffff;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        div.status {
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 6px;
          margin-right: 8px;

          &-ok {
            background-color: #00de46;
          }

          &-warn {
            background-color: #ffb900;
          }

          &-error {
            background-color: #ff0038;
          }

          &-unreachable {
            background-color: #d100b1;
          }

          &-disconnected {
            background-color: #7a8199;
          }
        }
      }

      div.disconnected {
        position: relative;
        width: $image-width;
        height: $image-height;
        background-color: #222;

        div.message {
          @include position-center-middle;
          @include font-proxima-bold;
          font-size: 24px;
          color: rgba(#ffffff, 0.5);
        }
      }

      img.composite {
        @include cursor-pointer;
        display: block;
        width: $image-width;
        height: $image-height;
      }
    }
  }

}

