@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-tooltip {
  @include animation-ui(keyframes-fade-in-02, 350ms);
  @include box-shadow;
  user-select: none;
  position: absolute;
  //box-sizing: content-box;
  overflow: hidden;
  z-index: $z-index-tooltip;

  > div.header {
    @include display-flex-row;
    align-items: flex-start;
    overflow: hidden;
    padding: 8px 24px 8px 8px;

    div.title {
      @include flex-auto-fill;
      padding: 8px 8px;

      div.label {
        @include font-proxima-bold;
        font-size: 14px;
        margin-bottom: 2px;
      }

      div.sublabel {
        @include font-proxima-regular;
        font-size: 14px;
        margin-bottom: 2px;
      }
    }
  }

  > div.content {
    padding: 0 16px 16px 16px;
  }

  > div.text {
    @include font-proxima-medium;
    font-size: 12px;
    padding: 12px;
  }

  &-style-light {
    background-color: $color-ui-background;

    > div.header {
      background-color: $color-field-01;

      div.title {
        div.label {
          color: $color-text-01;
        }

        div.sublabel {
          color: $color-text-03;
        }
      }
    }

    > div.text {
      color: $color-text-02;
    }
  }

  &-style-dark {
    background-color: $color-ui-background-dark;

    > div.header {
      background-color: $color-field-01-dark;

      div.title {
        div.label {
          color: $color-text-01-dark;
        }

        div.sublabel {
          color: $color-text-03-dark;
        }
      }
    }

    > div.text {
      color: $color-text-02-dark;
    }
  }
}
