// Scrollbar styling (webkit only!)
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff;
}

::-webkit-scrollbar-thumb:window-inactive {
  background-color: transparent;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

*:hover::-webkit-scrollbar-thumb {
  background-color: #979797;
}
