@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-overflow-menu {
  @include box-shadow;
  position: absolute;
  max-width: 350px;
  max-height: 38px*8;
  overflow: auto;
  z-index: $z-index-menu;
  border-radius: 4px;
  animation: keyframes-overflow-menu-slide-in 150ms;

  div.item {
    @include font-proxima-regular;
    @include cursor-pointer;
    position: relative;
    font-size: 14px;
    color: $color-text-02;
    padding: 10px 32px 11px 16px;

    &:before {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 10px;
      left: 12px;
      -webkit-mask-size: 16px 16px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-switch-on.svg");
      background-color: $color-text-03;
    }

    &-disabled {
      cursor: default !important;
    }

    &-selected {
      padding-left: 40px;

      &:before {
        content: "";
      }
    }

    &-unselected {
      padding-left: 40px;
    }

    &-separated {
      border-bottom: 1px solid $color-ui-03;
    }

    &-escalated {
      &:hover {
        color: $color-inverse-01 !important;
        background-color: $color-danger !important;
      }
    }
  }

  &-style-light {
    background-color: $color-ui-01;

    div.item {
      color: $color-text-02;

      &:hover {
        background-color: $color-hover;
      }

      &:before {
        background-color: $color-text-03;
      }

      &-disabled {
        color: $color-disabled-02 !important;

        &:hover {
          background-color: $color-ui-01 !important;
        }
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03;
      }

      &-escalated {
        &:hover {
          color: $color-inverse-01 !important;
          background-color: $color-danger !important;
        }
      }

      &-selected {
        color: $color-text-04;
        background-color: $color-interactive-01;

        &:before {
          background-color: $color-text-04;
        }

        &:hover {
          background-color: $color-interactive-01 !important;
        }
      }
    }
  }

  &-style-dark {
    background-color: $color-ui-01-dark;

    div.item {
      color: $color-text-02-dark;

      &:hover {
        background-color: $color-hover-dark;
      }

      &:before {
        background-color: $color-text-03-dark;
      }

      &-disabled {
        color: $color-disabled-02-dark !important;

        &:hover {
          background-color: $color-ui-01-dark !important;
        }
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03-dark;
      }

      &-escalated {
        &:hover {
          color: $color-inverse-01-dark !important;
          background-color: $color-danger-dark !important;
        }
      }

      &-selected {
        color: $color-text-04-dark;
        background-color: $color-interactive-01-dark;

        &:before {
          background-color: $color-text-04-dark;
        }

        &:hover {
          background-color: $color-interactive-01-dark !important;
        }
      }
    }
  }

  @keyframes keyframes-overflow-menu-slide-in {
    0% {
      opacity: 0;
      transform: translateY(-16px)
    }
    100% {
      opacity: 1;
      transform: translateY(0)
    }
  }
}
