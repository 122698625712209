@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-fleet-trip-detail {
  @include size-width-height(560px, 100%);
  box-sizing: border-box;

  > div.content {
    @include flex-auto-fill;
    overflow: auto;
    padding: 0 24px 24px 24px;

    > div.map {
      height: 360px;
    }
  }
}
