@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-violation-detail {
  @include size-width-height(560px, 100%);
  @include transition-ui(min-width);
  box-sizing: border-box;

  @media print {
    display: none;
  }
}
