@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-user-profile-form {
  @include size-width-height(648px, 480px);
  @include position-center-optical;
  animation: keyframes-detail-slide-in 300ms;

  > div.content {
    form {
      @include display-flex-row;

      div.fieldset:first-child {
        margin-right: 24px;
      }
    }
  }
}
