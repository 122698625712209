@import "~muklit/styles/mixins.scss";
@import "~muklit/styles/colors";
@import "../../styles/colors";

div.partial-percentage-formatter {
  @include display-flex-row;
  align-items: center;

  div.number {
    @include font-proxima-regular;
    width: 55px;
    min-width: 55px;
    font-size: 14px;
    color: $color-text-01-dark;
    margin-right: 8px;
    text-align: left;
    white-space: nowrap;
  }

  div.bar {
    @include size-width-height(160px, 6px);
    position: relative;
    background-color: $color-ui-01-dark;

    div.value {
      height: 100%;
      background-color: $color-ui-04-dark;
    }
  }

  &-kind-availability {
    div.bar {
      background-color: $color-availability-na;

      div.value {
        background-color: $color-availability-available;
      }
    }
  }

  &-kind-classification {
    div.number {
      color: $color-text-01;
    }

    div.bar {
      background-color: $color-ui-01;

      div.value {
        background-color: $color-interactive-03;
      }
    }
  }

  &-style-light {
    div.number {
      color: $color-text-01;
    }

    div.bar {
      background-color: $color-ui-01;

      div.value {
        background-color: $color-ui-04;
      }
    }
  }

  &-style-dark {
    div.number {
      color: $color-text-01-dark;
    }

    div.bar {
      background-color: $color-ui-01-dark;

      div.value {
        background-color: $color-ui-04-dark;
      }
    }
  }
}
