@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-connector-detail {
  @include size-width-height(600px, 100%);
  @include transition-ui(min-width);

  &-wide {
    min-width: 900px;
  }
}
