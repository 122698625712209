@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-document-form {
  @include size-width-height(468px, 420px);
  @include position-center-optical;
  animation: keyframes-detail-slide-in 300ms;

  div.file-input {
    margin-top: 24px;

    div.label {
      @include font-proxima-regular;
      font-size: 12px;
      color: $color-text-02;
    }

    div.input {
      position: relative;
      margin-top: 4px;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      // border-color: $color-ui-04;
      color: $color-text-01;
      background-color: $color-field-02;
      // width: 100%;
      height: 40px;
      padding: 0 16px;

      display: flex;
      align-items: center;

      // &:hover {
      //   border-color: $color-ui-04;
      // }

      input {
        @include font-proxima-regular;
        // box-sizing: border-box;
        // font-size: 14px;
        outline: none;
        // border-bottom: 1px solid transparent;
        // border-radius: 0;
        color: $color-text-01;
      }

      // &:after {
      //   position: absolute;
      //   width: 20px;
      //   height: 20px;
      //   top: 10px;
      //   right: 16px;
      //   -webkit-mask-size: 20px 20px;
      //   pointer-events: none;
      // }
    }
  }
}
