@import "../../styles/mixins";
@import "../../styles/colors";
@import "../../styles/positions";

div.muklit-date-input {
  div.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: $z-index-menu - 1;
  }

  &.muklit-date-input-active {
    div.overlay {
      display: block;
    }
  }

  div.label {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.helper {
    @include font-proxima-regular;
    font-size: 12px;
  }

  div.input {
    position: relative;
    margin-top: 4px;
    border-radius: 4px;

    input {
      @include font-proxima-regular;
      width: 100%;
      height: 40px;
      box-sizing: border-box;
      font-size: 14px;
      outline: none;
      padding: 0 16px;
      border-radius: 4px;

      &::-webkit-inner-spin-button,
      &::-webkit-calendar-picker-indicator {
          display: none;
          -webkit-appearance: none;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      right: 16px;
      pointer-events: none;
      mask-size: 16px 16px;
      -webkit-mask-size: 16px 16px;
      mask-image: url("../../assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
      -webkit-mask-image: url("../../assets/images/icons/tools/16/16-tools-dropdown_arrow.svg");
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 12px;
    margin-top: 4px;
  }

  &-transparent {
    div.input {
      input {
        background-color: transparent !important;
      }
    }
  }

  &-verified {
    div.input {
      input {
        padding-right: 46px;
      }

      &:after {
        content: "";
        -webkit-mask-image: url("../../assets/images/icons/notification/20/20-notification-success.svg");
      }
    }
  }

  &-style-light {
    div.label {
      color: $color-text-02;
    }

    div.helper {
      color: $color-text-05;
    }

    div.input {
      input {
        color: $color-text-01;
        background-color: $color-field-01;

        &::placeholder {
          color: $color-text-03;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01;
          box-shadow: 0 0 0 1000px $color-field-01 inset;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01 inset;
        }
      }


      &:after {
        background-color: $color-icon-02;
      }
    }

    &.muklit-date-input-bright {
      div.input {
        input {
          background-color: $color-field-02;
        }
      }
    }

    &.muklit-date-input-active {
      div.input {
        z-index: $z-index-menu;
      }
    }

    &.muklit-date-input-invalid {
      div.input {
        &:after {
          background-color: $color-support-01;
        }
      }

      div.message {
        color: $color-text-error;
      }
    }

    &.muklit-date-input-verified {
      div.input {
        &:after {
          background-color: $color-support-02;
        }
      }

      div.message {
        color: $color-support-02;
      }
    }

    &.muklit-date-input-disabled {
      div.label {
        color: $color-disabled-02;
      }

      div.helper {
        color: $color-disabled-02;
      }

      div.input {
        &:after {
          background-color: $color-disabled-02;
        }

        input {
          color: $color-disabled-02;

          &::placeholder {
            color: $color-disabled-02;
          }
        }
      }

      div.message {
        color: $color-disabled-02;
      }
    }
  }

  &-style-dark {
    div.label {
      color: $color-text-02-dark;
    }

    div.helper {
      color: $color-text-05-dark;
    }

    div.input {
      &:after {
        background-color: $color-disabled-02-dark;
      }

      input {
        color: $color-text-01-dark;
        background-color: $color-field-01-dark;

        &::placeholder {
          color: $color-text-03-dark;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active  {
          -webkit-text-fill-color: $color-text-01-dark;
          -webkit-box-shadow: 0 0 0 1000px $color-field-01-dark inset;
        }
      }

      &:after {
        background-color: $color-icon-02-dark;
      }
    }

    &.muklit-date-input-bright {
      div.input {
        input {
          background-color: $color-field-02-dark;
        }
      }
    }

    &.muklit-date-input-active {
    }

    &.muklit-date-input-invalid {
      div.input {
        &:after {
          background-color: $color-support-01-dark;
        }
      }

      div.message {
        color: $color-text-error-dark;
      }
    }

    &.muklit-date-input-verified {
      div.input {
        &:after {
          background-color: $color-support-02-dark;
        }
      }

      div.message {
        color: $color-support-02-dark;
      }
    }

    &.muklit-date-input-disabled {
      div.label {
        color: $color-disabled-02-dark;
      }

      div.helper {
        color: $color-disabled-02-dark;
      }

      div.input {
        input {
          &::placeholder {
            color: $color-disabled-02-dark;
          }
        }
      }

      div.message {
        color: $color-disabled-02-dark;
      }
    }
  }

}
