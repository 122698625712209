@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-city-detail {
  @include display-flex-row;
  @include box-shadow;
  position: absolute;
  left: 54px + 32px;
  top: 32px;
  right: 32px;
  bottom: 32px;
  background-color: $color-ui-background;
  //animation: keyframes-detail-fade-in 300ms;
  border-radius: 6px;

  &:hover {
    div.close {
      opacity: 1;
    }
  }

  > div.menu {
    @include size-width-height(320px, 100%);
    overflow: hidden;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  > div.content {
    @include flex-auto-fill;
    position: relative;
    height: 100%;
    overflow: auto;
    //scrollbar-gutter: stable;

    > div {
      margin: 0 auto;
    }
  }

  > div.close {
    @include size-width-height(40px, 40px);
    @include transition-ui(opacity);
    @include cursor-pointer;
    position: absolute;
    top: -12px;
    right: -12px;
    background-color: $color-field-01-dark;
    border-radius: 4px;
    opacity: 0;
    z-index: 2;

    &:after {
      content: "";
      position: absolute;
      left: 8px;
      top: 8px;
      width: 24px;
      height: 24px;
      background-color: $color-icon-02-dark;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-close.svg");
    }
  }

  @media print {
    box-shadow: unset;
    position: relative;
    left: 0;
    top: 0;

    > div.menu {
      display: none;
    }

    > div.content {
      height: unset;
    }

    > div.close {
      display: none;
    }
  }
}
