@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-pagination {
  @include display-flex-row;
  height: 48px;
  align-items: center;
  user-select: none;

  div.info {
    @include font-proxima-regular;
    min-width: 300px;
    font-size: 14px;
  }

  div.pages {
    @include flex-auto-fill;
    @include display-flex-row;
    align-items: center;
    justify-content: center;

    div.icon {
      @include size-width-height(20px, 40px);
      @include cursor-pointer;
      position: relative;

      &:after {
        position: absolute;
        top: 9px;
        width: 20px;
        height: 20px;
        content: "";
        -webkit-mask-size: 20px 20px;
      }

      &-left {
        &:after {
          -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_left.svg");
        }
      }

      &-right {
        &:after {
          -webkit-mask-image: url("../../assets/images/icons/tools/20/20-tools-dart_right.svg");
        }
      }

      &-disabled {
        &:hover {
          background-color: unset !important;
        }
      }
    }

    div.page {
      @include font-proxima-regular;
      @include cursor-pointer;
      @include transition-ui(border-bottom-color);
      width: 48px;
      min-width: 48px;
      height: 40px;
      box-sizing: border-box;
      text-align: center;
      font-size: 14px;
      overflow: hidden;
      white-space: nowrap;
      padding-top: 11px;
      margin: 0 4px;
      border-bottom: 2px solid transparent;

      &-editing {
        outline: none;
      }
    }

    div.of {
      @include font-proxima-regular;
      height: 40px;
      font-size: 14px;
      box-sizing: border-box;
      padding-top: 10px;
    }

    div.text {
      @include font-proxima-regular;
      font-size: 14px;
      width: 120px;
      text-align: center;
      padding: 0 8px 4px 8px;
    }
  }

  &-style-light {
    div.info {
      color: $color-text-03;
    }

    div.pages {
      div.icon {
        &:hover {
          background-color: $color-hover;
        }

        &:active {
          &:after {
            background-color: $color-disabled-03-dark;
          }
        }

        &:after {
          background-color: $color-ui-05;
        }

        &-disabled {
          cursor: default !important;

          &:after {
            background-color: $color-disabled-02;
          }
        }
      }

      div.page {
        @include transition-ui(background-color);
        color: $color-text-03;

        &:hover {
          background-color: $color-hover;
        }

        &-selected {
          color: $color-text-01;
          border-color: $color-text-01;
        }

        &-editing {
          background-color: $color-hover;
          border-color: $color-interactive-01;
        }
      }

      div.of {
        color: $color-text-03;
      }

      div.text {
        color: $color-text-03;
      }
    }
  }

  &-style-dark {
    div.info {
      color: $color-text-03-dark;
    }

    div.pages {
      div.icon {
        &:hover {
          background-color: $color-hover-dark;
        }

        &:active {
          &:after {
            background-color: $color-disabled-03-dark;
          }
        }

        &:after {
          background-color: $color-ui-05-dark;
        }

        &-disabled {
          cursor: default !important;

          &:after {
            background-color: $color-disabled-02-dark;
          }
        }
      }

      div.page {
        color: $color-text-03-dark;

        &:hover {
          background-color: $color-hover-dark;
        }

        &-selected {
          color: $color-text-01-dark;
          border-color: $color-text-01-dark;
        }

        &-editing {
          background-color: $color-hover-dark;
          border-color: $color-interactive-01-dark;
        }
      }

      div.of {
        color: $color-text-03-dark;
      }

      div.text {
        color: $color-text-03-dark;
      }
    }
  }
}
