@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-tabs {
  @include display-flex-row;
  align-items: center;

  div.tab {
    @include font-proxima-semi-bold;
    @include cursor-pointer;
    @include transition-ui(border-bottom-color);
    box-sizing: border-box;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 8px;
    margin-right: 16px;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    border-bottom-color: transparent;

    &:last-child {
      margin-right: 0;
    }

    &-disabled {
      cursor: not-allowed;
    }
  }

  &-style-light {
    div.tab {
      color: $color-text-03;

      &:hover {
        color: $color-text-01;
      }

      &-selected {
        color: $color-text-01 !important;
        border-bottom-color: $color-text-01 !important;
      }

      &-disabled {
        color: $color-text-06 !important;
      }
    }
  }

  &-style-dark {
    div.tab {
      color: $color-text-03-dark;

      &:hover {
        color: $color-text-01-dark;
      }

      &-selected {
        color: $color-text-01-dark !important;
        border-bottom-color: $color-text-01-dark !important;
      }

      &-disabled {
        color: $color-text-01 !important;
      }
    }
  }
}
