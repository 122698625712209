@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.partial-sign {

  &-parking {
    width: 130px;
    border: 2px solid #8b88ff;
    border-radius: 4px;

    div.sign {
      @include display-flex-row;
      align-items: center;
      justify-content: space-between;
      background-color: #8b88ff;
      padding-right: 8px;
      border: 2px solid #ffffff;
      border-radius: 2px;
      overflow: hidden;

      div.display {
        @include font-proxima-semi-bold;
        @include font-size-color(14px, #ffffff);
        //@include font-size-color(14px, #1f1f1f);
        min-width: 30px;
        background-color: #2f2f2f;
        /*border-left: 1px solid #000000;
        border-top: 1px solid #000000;
        border-right: 1px solid #3f3f3f;
        border-bottom: 1px solid #3f3f3f;*/
        text-align: right;
        padding: 4px 4px 4px 16px;
      }
    }
  }

}
