@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "~muklit/styles/positions";

div.invipo-traffic-event-heatmap {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  overflow: hidden;

  div.form {
    @include size-width-height(320px, 100%);
    box-sizing: border-box;
    padding: 24px 16px 0 16px;
    overflow: auto;

    div.fieldset {
      background-color: $color-ui-01;
      padding: 12px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
  }

  div.insight {
    @include flex-auto-fill;
    @include display-flex-column;
    position: relative;
    height: 100%;
    box-sizing: border-box;
    padding: 16px 24px 24px 24px;
    overflow: hidden;

    div.header {
      @include display-flex-row;
      height: 40px;
      min-height: 40px;
      align-items: center;
      margin-bottom: 8px;

      div.title {
        @include flex-auto-fill;
        @include font-proxima-semi-bold;
        font-size: 19px;
        color: $color-text-02;
        margin-right: 24px;
      }
    }

    div.map {
      flex-grow: 1;
    }

  }

  &-overlay {
    @include size-width-height(1920px, 1080px);
    @include position-center-optical;
  }
}
