@import "../../styles/mixins";
@import "../../styles/colors";

div.invipo-draw-map {
  position: relative;
  width: 100%;
  height: 100%;

  div.mapboxgl-canvas-container {
    canvas {
      outline: none;
      animation: keyframes-draw-map-fade-in 1200ms;
    }
  }

  div.mapboxgl-control-container {
    a.mapboxgl-ctrl-logo, div.mapboxgl-ctrl-attrib {
      display: none;
    }

    div.mapboxgl-ctrl-group {
      box-shadow: none;

      button {
        border: 0;
      }
    }
  }

  button.mapbox-gl-draw_trash {
    display: none;
  }

  div.placeholder-control {
    position: absolute;
    right: 8px;
    bottom: 8px;
    z-index: 1;
  }

  @keyframes keyframes-draw-map-fade-in {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
