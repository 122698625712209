.animation-fade-in-300 {
  opacity: 1 !important;
  animation: keyframes-fade-in-01 300ms;
}

.animation-fade-out-300 {
  opacity: 0 !important;
  animation: keyframes-fade-out-01 300ms;
}

@keyframes keyframes-fade-in-01 {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes keyframes-fade-in-02 {
  0%, 80% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes keyframes-fade-in-03 {
  0% { opacity: 0 }
  100% { opacity: 0.35 }
}

@keyframes keyframes-fade-out-01 {
  0% { opacity: 1 }
  100% { opacity: 0 }
}
