@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-data-table {
  @include display-flex-column;
  overflow: auto;

  table.data {
    width: 100%;

    thead {
      tr {
        height: 32px;
      }

      th {
        @include font-proxima-regular;
        position: sticky;
        top: 0;
        z-index: 1;
        font-size: 14px;
        vertical-align: center;
        white-space: nowrap;
        padding: 0;

        div.caption {
          @include display-flex-row;

          div.label {
            padding: 0 16px 0 16px;
          }

          div.order {
            display: none;
            width: 16px;
            height: 16px;
            -webkit-mask-size: 16px 16px;

            &-asc {
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-arrow_up.svg");
            }

            &-desc {
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-arrow_down.svg");
            }
          }

          &-left {
            justify-content: left;
          }

          &-right {
            justify-content: right;
          }

          &-center {
            justify-content: center;
          }
        }

        &.header-selected {
          div.caption {
            div.order {
              display: block;
            }
          }
        }

        &.header-sortable {
          div.caption {
            @include cursor-pointer;
          }
        }
      }
    }

    tbody {
      tr.row-group {
        td {
          max-width: 0;
          vertical-align: center;
          padding: 0;

          div.group {
            @include font-proxima-semi-bold;
            font-size: 12px;
            padding: 18px 16px 4px 16px;
            text-transform: uppercase;
            overflow: hidden;
          }
        }
      }

      tr.row-data {
        &:hover {
          @include cursor-pointer;

          div.menu {
            display: block;
          }
        }

        td {
          max-width: 0;
          vertical-align: center;
          border-bottom: 1px solid transparent;
          padding: 0;

          div.group {
            @include font-proxima-semi-bold;
            font-size: 12px;
            padding: 18px 16px 2px 16px;
            overflow: hidden;
          }

          div.checkbox {
            width: 20px;
            height: 20px;
            -webkit-mask-size: 20px 20px;
            margin-left: 8px;

            &-on {
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/20/20-tools-checkbox-on.svg");
            }

            &-off {
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/20/20-tools-checkbox-off.svg");
            }
          }

          div.cell {
            @include font-proxima-regular;
            font-size: 14px;
            padding: 0 16px 0 16px;
            overflow: hidden;

            &-left {
              text-align: left;
            }

            &-right {
              text-align: right;
            }

            &-center {
              text-align: center;
            }

            &-ellipsis {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
          }

          div.link,
          a.link {
            @include font-proxima-semi-bold;
            @include cursor-pointer;
            font-size: 14px;
            text-decoration: none;
          }

          div.menu {
            @include size-width-height(40px, 40px);
            @include cursor-pointer;
            @include transition-ui(background-color);
            position: relative;
            display: none;
            -webkit-mask-size: 24px 24px;

            &:hover {
              background-color: rgba(#000000, 0.1);
            }

            &:after {
              content: "";
              position: absolute;
              left: 8px;
              top: 8px;
              width: 24px;
              height: 24px;
              -webkit-mask-size: 24px 24px;
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-menu.svg");
            }
          }
        }

        &.row-decorator-warn {
          td {
            color: #fcb836;
          }
        }

        &.row-decorator-error {
          td {
            color: #ff0038;
          }
        }

        &.row-decorator-unread {
          td {
            div.cell {
              @include font-proxima-semi-bold;
            }
          }
        }
      }
    }
  }

  div.empty {
    @include flex-auto-fill;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    div.image {
      @include size-width-height(240px, 180px);
      margin: 64px 0 24px 0;
    }

    div.text {
      @include font-proxima-regular;
      font-size: 14px;
      text-align: center;
      margin-bottom: 80px;
    }
  }

  &-type-unselectable {
    table.data {
      tbody {
        tr.row-data {
          &:hover {
            cursor: default !important;
          }
        }
      }
    }
  }

  &-size-short {
    table.data {
      tbody {
        tr {
          height: 32px + 1px;
        }
      }
    }
  }

  &-size-normal {
    table.data {
      tbody {
        tr {
          height: 40px + 1px;
        }
      }
    }
  }

  &-size-tall {
    table.data {
      tbody {
        tr {
          height: 48px + 1px;
        }
      }
    }
  }

  &-style-light {
    table.data {
      thead {
        th {
          color: $color-text-01;
          background-color: $color-ui-background;
          border-bottom: 1px solid $color-hover;

          &:active {
            background-color: $color-hover;
          }

          div.caption {
            div.order {
              background-color: $color-text-01;
            }
          }
        }
      }

      tbody {
        tr.row-group {
          td {
            color: $color-text-03;
          }
        }

        tr.row-data {
          td {
            color: $color-text-02;
            border-color: $color-hover;

            div.checkbox {
              &-on {
                background-color: $color-ui-05;
              }

              &-off {
                background-color: $color-ui-04;
              }
            }

            div.link,
            a.link {
              color: $color-interactive-01;
            }

            div.menu {
              &:after {
                background-color: $color-text-05;
              }
            }
          }

          &:hover {
            background-color: $color-hover;

            td {
              border-color: transparent;
            }
          }

          &.row-selected {
            background-color: $color-highlight-02;

            td {
              border-color: transparent;
            }
          }
        }

        tr.row-decorator-disabled {
          td {
            color: $color-disabled-03;
          }
        }

        tr.row-decorator-deleted {
          td {
            color: $color-danger;
          }
        }
      }
    }

    div.empty {
      div.image {
        background-image: url("~muklit/assets/images/illustrations/other/no_data.svg");
      }

      div.text {
        color: $color-text-02;
      }
    }
  }

  &-style-dark {
    table.data {
      thead {
        th {
          color: $color-text-01-dark;
          background-color: $color-ui-background-dark;
          border-bottom: 1px solid $color-ui-03-dark;

          &:active {
            background-color: $color-hover-dark;
          }

          div.caption {
            div.order {
              background-color: $color-text-01-dark;
            }
          }
        }
      }

      tbody {
        tr.row-group {
          td {
            color: $color-text-03-dark;
          }
        }

        tr.row-data {
          td {
            color: $color-text-02-dark;
            border-color: $color-hover-dark;

            div.checkbox {
              &-on {
                background-color: $color-ui-05-dark;
              }

              &-off {
                background-color: $color-ui-04-dark;
              }
            }

            div.link,
            a.link {
              color: $color-interactive-01-dark;
            }

            div.menu {
              &:after {
                background-color: $color-text-05-dark;
              }
            }
          }

          &:hover {
            background-color: $color-hover-dark;

            td {
              border-color: transparent;
            }
          }

          &.row-selected {
            background-color: $color-highlight-02-dark;

            td {
              border-color: transparent;
            }
          }
        }

        tr.row-decorator-disabled {
          td {
            color: $color-disabled-03-dark;
          }
        }

        tr.row-decorator-deleted {
          td {
            color: $color-danger-dark;
          }
        }
      }
    }

    div.empty {
      div.image {
        background-image: url("~muklit/assets/images/illustrations/other/no_data-dark.svg");
      }

      div.text {
        color: $color-text-02-dark;
      }
    }
  }
}
