@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-light-controller-overview {

  div.diagram {
    margin-bottom: 24px;

    div.row {
      @include display-flex-row;
      //background-color: $color-field-01;
      margin-bottom: 4px;

      div.state {
        @include size-width-height(3px, 24px);
        background-color: $color-field-01;
        margin-right: 2px;

        &-on {
          background-color: $color-traffic_control-state-on;
        }

        &-off {
          background-color: $color-traffic_control-state-off;
        }

        &-dark {
          background-color: $color-traffic_control-state-dark;
        }

        &-flashing-yellow {
          background-color: $color-traffic_control-state-flashing_yellow;
        }

        &-unknown {
          background-color: $color-traffic_control-mode-unknown;
        }
      }

      div.mode {
        @include size-width-height(3px, 24px);
        background-color: $color-field-01;
        margin-right: 2px;

        &-local {
          background-color: $color-traffic_control-mode-local;
        }

        &-manual {
          background-color: $color-traffic_control-mode-manual;
        }

        &-special {
          background-color: $color-traffic_control-mode-special;
        }

        &-central {
          background-color: $color-traffic_control-mode-central;
        }

        &-central {
          background-color: $color-traffic_control-mode-unknown;
        }
      }

      div.plan {
        @include size-width-height(5px, 12px);
        position: relative;
        box-sizing: border-box;

        div.name {
          @include font-proxima-regular;
          @include font-size-color(11px, $color-text-03);
          white-space: nowrap;
          padding-left: 4px;
          transform: rotate(315deg);
        }

        &-name {
          z-index: 1;
          border-left: 2px solid $color-text-03;
        }
      }
    }

    div.range {
      @include display-flex-row;
      position: relative;
      justify-content: space-between;
      margin-top: 4px;

      div.value {
        @include font-proxima-regular;
        @include font-size-color(11px, $color-text-03);

        &-fixed {
          width: 40px;
          overflow: hidden;
        }

        &-bold {
          @include font-proxima-semi-bold;
        }
      }
    }

  }

}
