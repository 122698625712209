@import "~muklit/styles/positions";
@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-toast-manager {
  position: absolute;
  left: 56px + 8px;
  bottom: 8px;
  z-index: $z-index-toast;
}
