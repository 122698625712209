@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-city-list {
  @include display-flex-column;
  @include size-width-height(320px, 100%);
  @include box-shadow;
  box-sizing: border-box;
  background-color: $color-text-04;
  border-radius: 4px;
  margin-bottom: 8px;

  div.header {
    @include display-flex-row;
    position: relative;
    align-items: flex-start;
    user-select: none;
    padding: 16px 16px 16px 24px;

    div.title {
      @include flex-auto-fill;
      margin-top: 8px;

      div.label {
        @include font-proxima-semi-bold;
        @include font-size-color(19px, $color-ui-05);
        margin-bottom: 4px;
      }

      div.sublabel {
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-02);
      }
    }
  }

  div.toolbar {
    @include display-flex-row;
    padding: 0 24px 16px 16px;
    align-items: center;

    div.tag {
      @include font-proxima-regular;
      @include font-size-color(12px, $color-icon-03);
      padding: 4px 8px;
      background-color: $color-interactive-01;
      border-radius: 12px;
    }

    div.action {
      @include font-proxima-semi-bold;
      @include font-size-color(14px, $color-interactive-01);
      @include transition-ui(color);
      @include cursor-pointer;
      margin-left: auto;
    }
  }

  div.search {
    position: relative;
    background-color: $color-hover;
    margin: 0 18px 16px 12px;
    border-radius: 4px;

    input {
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-01);
      width: 100%;
      height: 40px;
      background-color: transparent;
      box-sizing: border-box;
      outline: none;
      padding: 0 44px;
    }

    div.find {
      position: absolute;
      width: 24px;
      height: 24px;
      left: 8px;
      top: 8px;
      background-color: $color-ui-09;
      -webkit-mask-size: 24px 24px;
      -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-search.svg");
    }

    div.clear {
      @include cursor-pointer;
      position: absolute;
      width: 20px;
      height: 20px;
      right: 8px;
      top: 10px;
      background-color: $color-ui-09;
      -webkit-mask-size: 20px 20px;
      -webkit-mask-image: url("~muklit/assets/images/icons/notification/20/20-notification-disabled.svg");

      &:hover {
        background-color: $color-ui-05;
      }
    }

    &-empty {
      div.clear {
        display: none;
      }
    }
  }

  div.content {
    @include flex-auto-fill;
    overflow: auto;
    padding: 0 12px 24px 12px;
    scrollbar-gutter: stable;

    div.item {
      @include display-flex-row;
      @include cursor-pointer;
      overflow: hidden;
      padding: 8px 12px 0 12px;
      border-radius: 4px;

      &:hover {
        background-color: $color-hover;

        div.description {
          border-bottom: 1px solid transparent;
        }
      }

      div.icon {
        @include size-width-height(24px, 24px);
        position: relative;
        border-radius: 50%;
        margin-right: 12px;

        &:after {
          @include size-width-height(20px, 20px);
          content: "";
          position: absolute;
          left: 2px;
          top: 2px;
          background-color: $color-ui-background;
          -webkit-mask-size: 20px 20px;
        }
      }

      div.title {
        div.label {
          @include font-proxima-semi-bold;
          @include font-size-color(14px, $color-text-01);
          margin-bottom: 2px;
        }

        div.description {
          @include font-proxima-regular;
          @include font-size-color(13px, $color-text-03);
          padding-bottom: 8px;
          border-bottom: 1px solid transparent;
        }
      }

      &-hidden {
        display: none;
      }
    }

    div.text {
      @include font-proxima-regular;
      @include font-size-color(13px, $color-text-01);
      padding: 0 12px 0 12px;
    }
  }
}
