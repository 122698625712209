@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "~muklit/styles/positions";

div.invipo-detail-menu {
  @include size-width-height(320px, 100%);
  box-sizing: border-box;
  padding: 16px 16px 16px 16px;

  div.header {
    @include display-flex-row;
    padding: 0 0 16px 0;

    div.title {
      @include flex-auto-fill;
      @include font-proxima-semi-bold;
      font-size: 19px;
      padding: 8px 8px 0 12px;
    }

    div.subtitle {
      @include font-proxima-regular;
      font-size: 12px;
      margin-top: 2px;
    }
  }

  div.items {
    div.item {
      @include display-flex-row;
      @include cursor-pointer;
      align-items: center;
      overflow: hidden;
      padding: 8px 12px 9px 12px;
      border-radius: 4px;

      div.label {
        @include flex-auto-fill;
        @include font-proxima-semi-bold;
        font-size: 14px;
      }

      &-disabled {
        cursor: default !important;
      }
    }
  }

  &-style-light {
    background-color: $color-field-01;

    div.header {
      div.title {
        color: $color-text-01;
      }

      div.subtitle {
        color: $color-text-05;
      }
    }

    div.items {
      div.title {
        color: $color-text-03;
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03;
      }

      div.item {
        &:hover {
          background-color: $color-hover;
        }

        div.label {
          color: $color-text-03;
        }

        &-selected {
          background-color: $color-interactive-02 !important;

          div.label {
            color: $color-text-04;
          }

          div.icon {
            background-color: $color-text-04-dark;
          }
        }

        &-disabled {
          div.label {
            color: $color-disabled-03 !important;
          }

          div.icon {
            background-color: $color-disabled-03;
          }

          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  &-style-dark {
    background-color: $color-field-01-dark;

    div.header {
      div.title {
        color: $color-text-01-dark;
      }

      div.subtitle {
        color: $color-text-05-dark;
      }
    }

    div.items {
      div.title {
        color: $color-text-03-dark;
      }

      &-separated {
        border-bottom: 1px solid $color-ui-03-dark;
      }

      div.item {
        &:hover {
          background-color: $color-hover-dark;
        }

        div.label {
          color: $color-text-03-dark;
        }

        &-selected {
          background-color: $color-interactive-02-dark !important;

          div.label {
            color: $color-text-04-dark;
          }

          div.icon {
            background-color: $color-text-04-dark;
          }
        }

        &-disabled {
          div.label {
            color: $color-disabled-03-dark !important;
          }

          div.icon {
            background-color: $color-disabled-03-dark;
          }

          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }

  @media print {
    display: none;
  }
}
