@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../styles/colors";

div.partial-malfunction-formatter {
  @include display-flex-row;
  align-items: center;
  position: relative;

  div.status {
    @include size-width-height(12px, 12px);
    position: relative;
    margin-right: 6px;
    border-radius: 8px;
    background-color: $color-ui-04-dark;

    &:after {
      @include font-proxima-extra-bold;
      content: "!";
      position: absolute;
      width: 100%;
      height: 100%;
      font-size: 11px;
      text-align: center;
      color: $color-ui-background-dark;
    }

    &-error {
      background-color: $color-status-error;
    }

    &-warn {
      background-color: $color-status-warn;
    }
  }

  div.message {
    @include font-proxima-regular;
    font-size: 14px;
    color: $color-text-01-dark;
  }
}
