@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-user-exports {
  @include flex-auto-fill;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: $color-ui-background;
}
