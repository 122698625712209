@import "../../styles/mixins";
@import "../../styles/colors";

div.invipo-basic-map {
  position: relative;
  width: 100%;
  height: 100%;

  div.mapboxgl-canvas-container {
    canvas {
      outline: none;
      animation: keyframes-basic-map-fade-in 1200ms;
    }
  }

  div.mapboxgl-control-container {
    display: none;
  }

  @keyframes keyframes-basic-map-fade-in {
    0% {
      opacity: 0;
    }
    70% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
