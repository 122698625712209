@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "invipo/styles/colors";

$image-width: 320px * 0.70;
$image-height: 180px * 0.70;

div.invipo-wall-cameras {
  @include flex-auto-fill;
  position: relative;
  height: 100%;
  background-color: #000000;

  div.cameras {
    @include display-flex-row;
    flex-wrap: wrap;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    align-content: flex-start;
    padding: 24px 0 24px 24px;

    div.camera {
      width: $image-width;
      margin: 0 8px 16px 0;

      div.title {
        @include display-flex-row;
        align-items: center;
        margin-bottom: 8px;

        div.status {
          width: 12px;
          height: 12px;
          background-color: #fff;
          border-radius: 6px;
          margin-right: 8px;

          &-ok {
            background-color: $color-status-ok-dark;
          }

          &-warn {
            background-color: $color-status-warn-dark;
          }

          &-error {
            background-color: $color-status-error-dark;
          }

          &-unreachable {
            background-color: $color-status-unreachable-dark;
          }

          &-disconnected {
            background-color: $color-status-disconnected-dark;
          }
        }

        div.label {
          @include flex-auto-fill;
          @include font-proxima-semi-bold;
          @include cursor-pointer;
          font-size: 19px;
          color: $color-text-01-dark;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      div.image {
        position: relative;
        width: $image-width;
        height: $image-height;
        background-color: $color-field-01-dark;
        border-radius: 4px;
        overflow: hidden;

        &:hover {
          div.control {
            @include display-flex-row;
          }

          img {
            opacity: 0.4;
          }
        }

        div.message {
          @include position-center-middle;
          @include font-proxima-bold;
          font-size: 24px;
          color: rgba(#ffffff, 0.5);
        }

        div.control {
          @include position-center-middle;
          display: none;

          div.icon {
            @include size-width-height(24px, 24px);
            @include cursor-pointer;
            background-color: $color-text-01-dark;
            margin: 0 2px;
            -webkit-mask-size: 24px 24px;

            &-photo {
              -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-photo.svg");
            }

            &-camera {
              -webkit-mask-image: url("~muklit/assets/images/icons/devices/24/24-devices-camera.svg");
            }
          }
        }

        img {
          @include transition-ui(opacity);
          @include cursor-pointer;
          display: block;
          width: $image-width;
          height: $image-height;
        }
      }
    }
  }

}

