@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-traffic-light-controller-overview {
  div.form {
    div.fieldset-time {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field:nth-child(1) {
        margin-right: 32px;
      }
    }

    div.fieldset-modifications {
      @include display-flex-row;
      flex-wrap: wrap;

      div.field {
        margin-right: 24px;
      }
    }

    &-switch-state {
      div.title-modifications {
        display: none;
      }

      div.fieldset-modifications {
        display: none;
      }
    }

    &-switch-plan {
    }
  }
}
