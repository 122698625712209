@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-parking-occupancy-form {
  @include size-width-height(468px, 400px);
  @include position-center-optical;
  animation: keyframes-detail-slide-in 300ms;

}
