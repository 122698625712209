@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-user-detail {
  @include size-width-height(460px, 100%);
  @include transition-ui(min-width);
  box-sizing: border-box;

  &-wide {
    min-width: 690px;
  }

}
