@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";
@import "invipo/styles/colors";

$image-width: 320px * 0.7;
$image-height: 320px * 0.7 * calc(9 / 16);

div.invipo-message-signs-insight {
  @include flex-auto-fill;
  @include display-flex-row;
  flex-wrap: wrap;
  overflow: auto;
  align-content: flex-start;
  padding: 24px 0 24px 24px;

  div.sign {
    width: $image-width;
    margin: 0 8px 12px 0;

    div.title {
      @include display-flex-row;
      align-items: center;
      margin-bottom: 8px;

      div.label {
        @include flex-auto-fill;
        @include font-proxima-semi-bold;
        font-size: 19px;
        color: $color-text-01;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div.status {
        width: 12px;
        height: 12px;
        background-color: #fff;
        border-radius: 6px;
        margin-right: 8px;

        &-ok {
          background-color: $color-status-ok-dark;
        }

        &-warn {
          background-color: $color-status-warn-dark;
        }

        &-error {
          background-color: $color-status-error-dark;
        }

        &-unreachable {
          background-color: $color-status-unreachable-dark;
        }

        &-disconnected {
          background-color: $color-status-disconnected-dark;
        }
      }
    }

    div.disconnected {
      position: relative;
      width: $image-width;
      height: $image-height;
      background-color: #222;

      div.message {
        @include position-center-middle;
        @include font-proxima-bold;
        font-size: 24px;
        color: rgba(#ffffff, 0.5);
      }
    }

    div.screen {
      width: $image-width;
      height: $image-height;
      //border: 4px solid $color-icon-01;

      img {
        display: block;
        width: 100%;
      }
    }
  }

}

