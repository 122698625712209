@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-monitoring-hud {
  width: 300px;
  padding: 16px 24px;
  border-radius: 4px;
  background-color: $color-field-01-dark;

  div.header {
  }

  div.availability {

    div.value {
      @include font-proxima-semi-bold;
      @include font-size-color(19px, $color-text-01-dark);
    }

    div.label {
      @include font-proxima-regular;
      @include font-size-color(13px, $color-text-03-dark);
    }
  }

  div.history {
    margin: 24px 0;

    div.label {
      @include font-proxima-regular;
      @include font-size-color(14px, $color-text-03-dark);
      margin-bottom: 2px;
    }

    div.columns {
      @include display-flex-row;
      height: 60px;
      min-height: 60px;
      background-color: $color-hover-dark;
      justify-content: flex-end;

      div.column {
        width: 5px;
        min-width: 5px;

        div.status {
          width: 100%;

          &-ok {
            background-color: $color-status-ok;
          }

          &-warn {
            background-color: $color-status-warn;
          }

          &-error {
            background-color: $color-status-error;
          }

          &-unreachable {
            background-color: $color-status-unreachable;
          }

          &-disconnected {
            background-color: $color-status-disconnected;
          }
        }
      }
    }
  }

  div.bar {
    @include display-flex-row;
    height: 12px;
    min-height: 12px;
    margin-bottom: 12px;

    div.status {
      height: 100%;

      &:first-child {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      &:last-child {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }

      &-ok {
        background-color: $color-status-ok;
      }

      &-warn {
        background-color: $color-status-warn;
      }

      &-error {
        background-color: $color-status-error;
      }

      &-unreachable {
        background-color: $color-status-unreachable;
      }

      &-disconnected {
        background-color: $color-status-disconnected;
      }
    }
  }

  div.legend {
    margin-top: 12px;

    div.row {
      @include display-flex-row;
      align-items: center;
      padding: 4px 0;
      box-sizing: border-box;

      div.status {
        @include size-width-height(10px, 10px);
        border-radius: 50%;
        background-color: #dddddd;
        margin-right: 8px;

        &-ok {
          background-color: $color-status-ok;
        }

        &-warn {
          background-color: $color-status-warn;
        }

        &-error {
          background-color: $color-status-error;
        }

        &-unreachable {
          background-color: $color-status-unreachable;
        }

        &-disconnected {
          background-color: $color-status-disconnected;
        }
      }

      div.label {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-02-dark);
        padding-right: 4px;
      }

      div.count {
        @include flex-auto-fill;
        @include font-proxima-regular;
        @include font-size-color(13px, $color-text-03-dark);
        text-align: right;
        padding-right: 4px;
      }

      div.percent {
        @include font-proxima-medium;
        @include font-size-color(13px, $color-text-03-dark);
        padding-right: 4px;
        text-align: right;
      }
    }
  }
}
