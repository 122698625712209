@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-monitoring-map {
  @include flex-auto-fill;
  @include display-flex-column;
  @include transition-ui(opacity);
  height: 100%;
  position: relative;
  background-color: $color-ui-background-dark;
  overflow: hidden;

  > div.map {
    @include flex-auto-fill;
    position: relative;
    z-index: 0;

    div.placeholder {
      position: absolute;
      z-index: 1;

      &-search {
        left: 8px;
        top: 8px;
      }

      &-control {
        right: 8px;
        bottom: 8px;
      }

      &-hud {
        right: 8px;
        top: 8px;
      }
    }
  }

  > div.detail {
  }
}
