@import "~muklit/styles/positions";
@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";

div.invipo-item-detail-form {
  // @include size-width-height(648px, 480px);
  @include size-width-height(960px, 720px);
  @include position-center-optical;

  > div.content-general {
    form {
      @include display-flex-row;

      div.fieldset:first-child {
        margin-right: 24px;
      }
    }
  }

  > div.content-map {
    padding: 0;
  }
}
