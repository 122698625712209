@import "~muklit/styles/colors";
@import "~muklit/styles/mixins";
@import "../../../styles/colors";

div.invipo-area-list {

  div.content {
    div.item {
      div.icon {
        &-area {
          background-color: transparent;

          &:after {
            background-color: $color-icon-01;
            -webkit-mask-image: url("~muklit/assets/images/icons/tools/24/24-tools-area.svg");
          }
        }
      }
    }
  }

}
