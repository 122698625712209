@import "../../styles/mixins";
@import "../../styles/colors";

div.muklit-toggle {
  @include display-flex-row;
  align-items: center;
  margin-bottom: 24px;

  input {
    display: none;
  }

  div.track {
    @include size-width-height(48px, 24px);
    @include transition-ui(background-color);
    @include cursor-pointer;

    div.handle {
      @include transition-ui(margin-left);
      position: relative;

      &:after {
        content: "";
        position: absolute;
        display: block;
        width: 16px;
        height: 16px;
        background-color: $color-field-02;
        -webkit-mask-size: 16px 16px;
      }
    }
  }

  div.label {
    @include font-proxima-regular;
    cursor: default;
    font-size: 14px;
    color: $color-text-01;
    margin-left: 8px;
    user-select: none;
  }

  &-toggled-off {
    div.track {
      background-color: $color-ui-06;

      div.handle {
        background-color: $color-ui-04;
        margin-left: 0;

        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-switch-off.svg");
        }
      }
    }
  }

  &-toggled-on {
    div.track {
      background-color: $color-interactive-05;

      div.handle {
        background-color: $color-interactive-01;
        margin-left: 50%;

        &:after {
          -webkit-mask-image: url("~muklit/assets/images/icons/tools/16/16-tools-switch-on.svg");
        }
      }
    }
  }

  &-size-small {
    div.track {
      @include size-width-height(32px, 16px);

      div.handle {
        @include size-width-height(16px, 16px);

        &:after {
          left: 0;
          top: 0;
        }
      }
    }
  }

  &-size-tall {
    div.track {
      @include size-width-height(48px, 24px);

      div.handle {
        @include size-width-height(24px, 24px);

        &:after {
          left: 4px;
          top: 4px;
        }
      }
    }
  }

  &-style-light {
  }

  &-style-dark {
  }
}
