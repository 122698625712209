@import "~muklit/styles/_mixins.scss";
@import "~muklit/styles/_colors.scss";
@import "~muklit/styles/_animations.scss";

div.invipo-area-detail {
  @include size-width-height(460px, 100%);
  @include transition-ui(min-width);
  box-sizing: border-box;

  &-wide {
    min-width: 690px;
  }
}
