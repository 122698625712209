@import "~muklit/styles/mixins";
@import "~muklit/styles/colors";

div.invipo-wall-map {
  @include flex-auto-fill;
  @include display-flex-row;
  height: 100%;
  position: relative;
  background-color: $color-ui-background-dark;
  overflow: hidden;

  div.map {
    @include flex-auto-fill;
    position: relative;
    overflow: hidden;

    div.placeholder {
      position: absolute;
      z-index: 1;

      &-hud {
        right: 24px;
        top: 24px;
      }
    }
  }

}
